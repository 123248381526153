import { ReactNode, useEffect } from 'react';
import { AuthProvider } from 'react-oidc-context';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { Icon } from '@react-fe/common-ui';
import useBaseStore from '@react-fe/expertunity-base/stores';
import { Config } from '@react-fe/expertunity-base/models';
import oauthInterceptor from './interceptors/oauth-interceptor';

interface ConfigProviderProps {
  children: ReactNode;
}

export function ConfigProvider({ children }: Readonly<ConfigProviderProps>) {
  const { setConfig, config } = useBaseStore();
  const isDev = import.meta.env.MODE === 'development';
  const { isLoading, data } = useQuery<Config>({
    queryKey: ['config'],
    queryFn: async (): Promise<Config> => {
      const response = await axios.get<any>('/config');
      return response.data;
    },
    enabled: !isDev,
  });

  useEffect(() => {
    if (isDev) {
      setConfig({
        oidc_authority: import.meta.env.VITE_OIDC_AUTHORITY,
        oidc_client_id: import.meta.env.VITE_OIDC_CLIENT_ID,
        oidc_client_secret: import.meta.env.VITE_OIDC_CLIENT_SECRET,
        currency_symbol: import.meta.env.VITE_CURRENCY_SYMBOL,
      });
      return;
    }

    if (data) {
      setConfig(data);
    }
  }, [setConfig, data, isDev]);

  if (isLoading || !config?.oidc_client_id || !config?.oidc_authority || !config?.oidc_client_secret) {
    return (
      <div className={'flex items-center justify-center min-h-screen'}>
        <Icon icon={faSpinnerThird} className={'animate-spin size-40'} />
      </div>
    );
  }

  const oidcConfig = {
    authority: config.oidc_authority,
    client_id: config.oidc_client_id,
    client_secret: config.oidc_client_secret,
    redirect_uri: window.location.origin + window.location.pathname,
    post_logout_redirect_uri: window.location.origin + window.location.pathname,
    response_type: 'code',
    scope: 'email',
  };

  oauthInterceptor(config);

  return <AuthProvider {...oidcConfig}>{children}</AuthProvider>;
}

export default ConfigProvider;
