import React, { cloneElement, forwardRef, ReactElement, useCallback, useMemo, useRef } from 'react';
import { CoreComponent, CoreComponentProps, ElementContent, useMergedRef, withStaticProps } from '@react-fe/core';
import { ComponentDefaultTestId, getTestId } from '@react-fe/common-ui';
import { ButtonInputType, ButtonSize, ButtonType } from './button.constants';
import { Text, TextProps } from '../text';
import { Icon } from '../icon';
import cx from 'classnames';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { Button as ButtonMUI } from '@mui/material';

export interface ButtonRootProps extends CoreComponentProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type?: ButtonType;
  size?: ButtonSize;
  inputType?: ButtonInputType;
  loading?: boolean;
  disabled?: boolean;
  children?: ElementContent;
  fullWidth?: boolean;
}

const ButtonRootComponent: CoreComponent<ButtonRootProps, HTMLButtonElement> & {
  sizes?: typeof ButtonSize;
  types?: typeof ButtonType;
  inputType?: typeof ButtonInputType;
} = forwardRef<HTMLButtonElement, ButtonRootProps>(
  (
    {
      id,
      className,
      'data-testid': dataTestId,
      onClick,
      type = ButtonRoot.types?.PRIMARY,
      size = ButtonRoot.sizes?.MEDIUM,
      inputType = ButtonRoot.inputType?.BUTTON,
      disabled,
      loading,
      children,
      fullWidth,
    },
    ref,
  ) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const mergedRef = useMergedRef(ref, buttonRef);

    const classNames = cx(className, [`button-type-${type}`], [`button-size-${size}`], ['gap-spacing-1']);

    const onButtonClicked = useCallback(
      (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (disabled || loading) {
          event.preventDefault();
          return;
        }

        if (onClick) {
          onClick(event);
        }
      },
      [onClick, disabled, loading],
    );

    const buttonProps = useMemo(() => {
      const props: Record<string, unknown> = {
        id,
        'data-testid': dataTestId || getTestId(ComponentDefaultTestId.BUTTON, id),
        className: classNames,
        disabled,
        ref: mergedRef,
        type: inputType,
        onClick: onButtonClicked,
        fullWidth,
      };
      return props;
    }, [id, dataTestId, classNames, disabled, mergedRef, inputType, onButtonClicked, fullWidth]);

    const enhancedChildren = React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        if (child.type === Text) {
          return cloneElement(
            child as ReactElement,
            {
              color:
                type !== ButtonRoot.types?.OUTLINED && type !== ButtonRoot.types.GHOST
                  ? Text.colors?.LIGHT
                  : Text.colors?.TEXT_PRIMARY,
            } as TextProps,
          );
        }
      }
      return child;
    });

    const commonFocusStyles = {
      border: '1px solid',
      borderColor: 'warning.light',
      outlineColor: 'warning.light',
    };

    const commonButtonStyles = {
      textTransform: 'none',
      borderRadius: '100px',
      fontWeight: '600',
      fontSize: '16px',
      border: '1px solid',
      borderColor: 'transparent',
      fontFamily: 'DM Sans, serif',
    };

    const buttonTypeStyles = {
      primary: {
        backgroundColor: 'primary.main',
        color: 'primary.contrastText',
        '&:hover': {
          border: '1px solid',
          borderColor: 'primary.dark',
        },
        '&:focus, &:focus-visible': commonFocusStyles,
        '&:active': {
          border: '1px solid',
          borderColor: 'transparent',
          backgroundColor: 'primary.dark',
        },
      },
      secondary: {
        border: '1px solid',
        backgroundColor: 'secondary.main',
        borderColor: 'transparent',
        color: 'secondary.contrastText',
        '&:hover': {
          border: '1px solid',
          borderColor: 'secondary.light',
        },
        '&:focus, &:focus-visible': commonFocusStyles,
        '&:active': {
          border: '1px solid',
          borderColor: 'transparent',
          backgroundColor: 'secondary.dark',
        },
      },
      outlined: {
        backgroundColor: 'transparent',
        color: 'primary.main',
        border: '1px solid',
        borderColor: 'primary.main',
        '&:hover': {
          backgroundColor: 'primary.main',
          color: 'primary.contrastText',
        },
        '&:focus, &:focus-visible': commonFocusStyles,
      },
      warning: {
        backgroundColor: 'error.dark',
        color: 'error.contrastText',
        border: '1px solid',
        borderColor: 'transparent',
        '&:hover': {
          border: '1px solid',
          borderColor: 'error.dark',
        },
        '&:focus, &:focus-visible': commonFocusStyles,
      },
      ghost: {
        alignItems: 'center',
        color: 'text.primary',
        backgroundColor: 'transparent',
        minWidth: '0',
        border: 'none',
        '&:hover': {
          border: 'none',
          opacity: '0.8',
        },
        '&:focus, &:focus-visible': {
          border: 'none',
          outline: 'none',
        },
        '&:active': {
          border: 'none',
          backgroundColor: 'transparent',
        },
      },
    };

    const buttonSizeStyles = {
      xsmall: {
        padding: '0',
        height: '16px',
        width: '16px',
        fontSize: '16px',
      },
      small: {
        padding: '10px 6px',
        height: 32,
      },
      medium: {
        padding: '12px 14px',
        height: 44,
      },
      large: { padding: '14px 30px', height: 56 },
    };

    const disabledStyles = {
      cursor: 'not-allowed',
      backgroundColor: 'grey.200',
      borderColor: 'grey.200',
      color: 'text.secondary',
      '&:focus, &:hover, &:active': {
        outline: 'unset',
        borderColor: 'transparent',
        backgroundColor: 'grey.200',
        color: 'text.secondary',
      },
    };

    return (
      <ButtonMUI
        disableRipple
        sx={{
          '&.MuiButtonBase-root': commonButtonStyles,
          '&.button-type-primary': buttonTypeStyles.primary,
          '&.button-type-secondary': buttonTypeStyles.secondary,
          '&.button-type-outlined': buttonTypeStyles.outlined,
          '&.button-type-warning': buttonTypeStyles.warning,
          '&.button-type-ghost': buttonTypeStyles.ghost,
          '&.button-size-medium': buttonSizeStyles.medium,
          '&.button-size-xsmall': buttonSizeStyles.xsmall,
          '&.button-size-small': buttonSizeStyles.small,
          '&.button-size-large': buttonSizeStyles.large,
          '&:disabled': disabledStyles,
        }}
        {...buttonProps}
      >
        {loading ? (
          <>
            <span>{enhancedChildren}</span>
            <Icon className="animate-spin" icon={faSpinner} />
          </>
        ) : (
          enhancedChildren
        )}
      </ButtonMUI>
    );
  },
);

export const ButtonRoot = withStaticProps(ButtonRootComponent, {
  sizes: ButtonSize,
  types: ButtonType,
  inputType: ButtonInputType,
});

ButtonRoot.displayName = 'Button.Root';

export default ButtonRoot;
