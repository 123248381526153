import { CoreComponentProps, ElementContent } from '@react-fe/core';
import { Badge as MUIBadge, SxProps } from '@mui/material';
import { ReactNode } from 'react';

export interface BadgeProps extends CoreComponentProps {
  children: ElementContent;
  variant?: 'standard' | 'dot';
  content?: ReactNode;
  invisible?: boolean;
  customSx?: SxProps;
}

export function Badge({ children, content, invisible = false, variant = 'standard', customSx }: BadgeProps) {
  return (
    <MUIBadge
      badgeContent={content}
      classes={{ badge: 'bg-info-state-outlined-contrast text-primary-contrast' }}
      variant={variant}
      invisible={invisible}
      overlap="circular"
      sx={{
        '& .MuiBadge-badge': {
          fontSize: '0.75rem',
          lineHeight: '166.667%',
        },
        ...customSx,
      }}
    >
      {children}
    </MUIBadge>
  );
}

export default Badge;
