import { Autocomplete, FormControl, FormError, OptionItem, TextField } from '@react-fe/common-ui';
import { CoreComponent, CoreComponentProps, useDebounce, useMergedRef } from '@react-fe/core';
import { Namespaces } from '@react-fe/expertunity-base/constants';
import { useGeonames } from '@react-fe/expertunity-base/hooks';
import { Geoname } from '@react-fe/expertunity-base/models';
import cx from 'classnames';
import { useFormikContext } from 'formik';
import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddressFormControlNames } from '../../address-form.constants';

export type ZipInputProps = CoreComponentProps & { required: boolean };

export const ZipInput: CoreComponent<ZipInputProps, HTMLInputElement> = forwardRef<HTMLInputElement, ZipInputProps>(
  ({ id, 'data-testid': dataTestId, className, required }, ref) => {
    const internalRef = useRef<HTMLInputElement>(null);
    const mergedRef = useMergedRef(ref, internalRef);
    const classNames = cx(className, 'w-1/2');

    const { t } = useTranslation();
    const { setFieldValue, getFieldMeta, setFieldTouched, getFieldProps, validateForm, values } = useFormikContext();

    const [inputValue, setInputValue] = useState('');
    const [selectedCity, setSelectedCity] = useState<string>('');

    const { data } = useGeonames(
      getFieldMeta(AddressFormControlNames.ZIP).value as string,
      getFieldMeta(AddressFormControlNames.COUNTRY).value as string,
      getFieldMeta(AddressFormControlNames.COUNTRY).value as string,
      'postalcode',
    );

    const city = (values as any)[AddressFormControlNames.CITY];
    const fieldValue = (values as any)[AddressFormControlNames.ZIP];
    const fieldErrors = getFieldMeta(AddressFormControlNames.ZIP).error;
    const debouncedValidateForm = useDebounce(validateForm, 500);

    const extractZipCode = useCallback((input: string): string => {
      if (!input) return '';

      const trimmedInput = input.trim();
      if (!trimmedInput.includes(',')) return trimmedInput;

      const parts = trimmedInput.split(',');
      const zipPart = parts[0].trim();
      return zipPart || trimmedInput;
    }, []);

    const handleInputChange = useCallback(
      (newInputValue: string) => {
        const zipCode = extractZipCode(newInputValue);
        setInputValue(zipCode);
        setFieldValue(AddressFormControlNames.ZIP, zipCode, false);
        debouncedValidateForm();
      },
      [setFieldValue, extractZipCode, debouncedValidateForm],
    );

    const handleChange = useCallback(
      (newValue: string | Geoname | null) => {
        if (newValue && typeof newValue !== 'string') {
          setFieldValue(AddressFormControlNames.ZIP, newValue.postalCode, false);
          if (newValue.placeName) {
            setFieldValue(AddressFormControlNames.CITY, newValue.placeName, false);
          }
        } else {
          setFieldValue(AddressFormControlNames.ZIP, newValue || '', true);
        }

        debouncedValidateForm();
      },
      [setFieldValue, debouncedValidateForm],
    );

    const getOptionLabel = useCallback((option: Geoname | string) => {
      if (typeof option === 'string') return option;
      return `${option.postalCode}, ${option.placeName}`;
    }, []);

    const isOptionEqualToValue = useCallback((option: string | Geoname, value: string | Geoname) => {
      if (typeof option === 'string' || typeof value === 'string') {
        return (
          (typeof option === 'string' ? option : option.postalCode) ===
          (typeof value === 'string' ? value : value.postalCode)
        );
      }
      return option.postalCode === value.postalCode && option.placeName === value.placeName;
    }, []);

    const renderOption = useCallback(
      (props: any, option: Geoname) => {
        const { key: _, ...restProps } = props as any;
        const isSelected = isOptionEqualToValue(option, {
          postalCode: fieldValue,
          placeName: selectedCity,
          countryCode: getFieldMeta(AddressFormControlNames.COUNTRY).value as string,
        });

        return (
          <OptionItem
            key={`zip-input-render-option-${option.postalCode.concat(option.placeName)}`}
            isSelected={isSelected}
            option={{ value: option.postalCode, label: `${option.postalCode}, ${option.placeName}` }}
            {...restProps}
          />
        );
      },
      [isOptionEqualToValue, getFieldMeta, fieldValue, selectedCity],
    );

    const uniquePostalCodes = useMemo(() => {
      if (!data?.postalCodes) return [];
      return Array.from(new Map(data.postalCodes.map(item => [`${item.postalCode}-${item.placeName}`, item])).values());
    }, [data?.postalCodes]);

    const zipInputProps = useMemo(() => {
      return {
        id,
        'data-testid': dataTestId,
        className: classNames,
        ref: mergedRef,
      };
    }, [id, dataTestId, classNames, mergedRef]);

    useEffect(() => {
      setSelectedCity(city || '');
    }, [city]);

    return (
      <FormControl.Root name={AddressFormControlNames.ZIP} {...zipInputProps}>
        <Autocomplete
          value={fieldValue}
          inputValue={inputValue}
          onInputChange={(_, newInputValue) => handleInputChange(newInputValue)}
          onChange={(_, newValue) => handleChange(newValue)}
          options={uniquePostalCodes}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={isOptionEqualToValue}
          renderOption={renderOption}
          renderInput={params => {
            return (
              <TextField
                {...params}
                required={required}
                error={!!fieldErrors}
                label={t('address_form_zip_label', { ns: Namespaces.ProjectsPage })}
                placeholder={t('address_form_zip_placeholder', { ns: Namespaces.ProjectsPage })}
                onBlur={() => {
                  setFieldTouched(AddressFormControlNames.ZIP, true, true).catch();
                }}
              />
            );
          }}
        />
        <FormError field={getFieldProps(AddressFormControlNames.ZIP)} />
      </FormControl.Root>
    );
  },
);

ZipInput.displayName = 'ZipInput';

export default ZipInput;
