import React, { forwardRef, ReactNode, useMemo, useRef, useState } from 'react';
import styles from './dropdown.module.scss';
import { CoreComponent, CoreComponentProps, ElementContent, useMergedRef } from '@react-fe/core';
import { Select, MenuItem, SelectProps as MuiSelectProps, SxProps } from '@mui/material';
import { TooltipPlacement } from '../tooltip/tooltip.constant';
import Tooltip from '../tooltip/tooltip';
import cx from 'classnames';

export interface DropdownItem {
  value: any;
  label: string;
  disabled?: boolean;
}

export interface DropdownProps extends CoreComponentProps {
  value?: any;
  defaultValue?: any;
  name?: string;
  label?: string;
  labelId?: string;
  required?: boolean;
  onChange?: (event: React.ChangeEvent<{ value: any }>) => void;
  inputClassName?: string;
  disabled?: boolean;
  options?: DropdownItem[];
  withMaxHeightDropdown?: boolean;
  withMaxWidthDropdown?: boolean;
  fullWidth?: boolean;
  autoWidth?: boolean;
  error?: boolean;
  children?: ElementContent;
  renderValue?: (value: any) => ReactNode;
  multiple?: boolean;
  customSx?: SxProps;
}

export const Dropdown: CoreComponent<DropdownProps, HTMLDivElement> = forwardRef<HTMLDivElement, DropdownProps>(
  (
    {
      id,
      'data-testid': dataTestId,
      className,
      value,
      defaultValue = '',
      name = '',
      label = '',
      labelId = '',
      required = false,
      inputClassName = '',
      disabled = false,
      onChange,
      options,
      withMaxHeightDropdown = true,
      autoWidth = false,
      withMaxWidthDropdown = false,
      fullWidth = false,
      error = false,
      children,
      renderValue,
      multiple = false,
      customSx,
    },
    ref,
  ) => {
    const internalRef = useRef<HTMLDivElement>(null);
    const mergedRef = useMergedRef(ref, internalRef);
    const [isFocused, setIsFocused] = useState(false);
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleChange = (event: React.ChangeEvent<{ value: string }>) => {
      if (!disabled && onChange) {
        onChange(event as React.ChangeEvent<{ value: string }>);
      }
    };

    const handleFocus = () => {
      setIsFocused(true);
    };

    const handleBlur = () => {
      setIsFocused(false);
    };

    const selectProps: MuiSelectProps = useMemo(() => {
      return {
        id,
        'data-testid': dataTestId,
        ref: mergedRef,
        inputProps: { 'aria-label': 'controlled' },
        disabled,
        value,
        label,
        labelId,
        required,
        defaultValue,
        name,
        error,
        renderValue,
        multiple,
      };
    }, [
      id,
      dataTestId,
      mergedRef,
      disabled,
      value,
      label,
      labelId,
      required,
      defaultValue,
      name,
      error,
      renderValue,
      multiple,
    ]);

    const renderOption = (label: string) => {
      if (withMaxWidthDropdown && label.length > 20) {
        return (
          <Tooltip title={label} placement={TooltipPlacement.RIGHT} enterDelay={0}>
            <>{label.slice(0, 15)}...</>
          </Tooltip>
        );
      } else {
        return label;
      }
    };

    return (
      <Select
        {...selectProps}
        onChange={handleChange as MuiSelectProps['onChange']}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className={cx([className, styles.dropdownWrapper], {
          [styles.wAuto]: autoWidth,
          [styles.focused]: isFocused,
          [styles.fullWidth]: fullWidth,
          [styles.error]: error,
          [styles.disabled]: disabled,
        })}
        classes={{ select: inputClassName }}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        sx={{
          '& .MuiFormLabel-root': {
            top: '4px',
            left: '6px',
            background: 'white',
            paddingLeft: '6px',
            paddingRight: '6px',
          },
          ...customSx,
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              '&::-webkit-scrollbar': {
                width: '6px',
                borderRadius: '100px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                padding: '10px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
                padding: '10px',
              },
              marginTop: '9px',
              width: withMaxWidthDropdown ? '200px' : 'auto',
              boxShadow: '0 0 0',
              border: '1px solid #EAEAEA',
              maxHeight: withMaxHeightDropdown ? '210px' : 'auto',
              borderRadius: '0.5rem',
              '& .MuiMenu-list': {
                padding: 0,
                borderColor: '#737373',
              },
              '& .MuiMenuItem-root': {
                padding: '12px 20px',
              },
            },
          },
        }}
      >
        {children
          ? children
          : options?.map(option => (
              <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
                {renderOption(option.label)}
              </MenuItem>
            ))}
      </Select>
    );
  },
);

Dropdown.displayName = 'Dropdown';

export default Dropdown;
