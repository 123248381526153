export enum TenderTabName {
  ALL = 'all',
  ACTIONABLE = 'actionable',
  DRAFT = 'draft',
  OPEN = 'open',
  REVIEW = 'review',
  AWARDED = 'awarded',
  ARCHIVED = 'archived',
  DETAILS = 'details',
}
