import cx from 'classnames';
import { Text } from '../../../text/text';
import { MultiSelectFilterOption } from '../../multi-select-filter-input.constants';

type OptionItemProps = React.HTMLAttributes<HTMLLIElement> & {
  option: MultiSelectFilterOption;
  isSelected: boolean;
  optionMap?: Map<string, { value: string; label: string; parents: { value: string; label: string }[] }>;
};

export const OptionItem = ({ option, isSelected: isSelectedParent, optionMap, ...props }: OptionItemProps) => {
  const { 'aria-selected': isSelectedProps } = props;
  const optionData = optionMap !== undefined ? optionMap.get(option.value) : null;
  const isSelected = isSelectedParent !== undefined ? isSelectedParent : isSelectedProps;

  return (
    <li
      {...props}
      className={cx(
        'flex flex-col gap-spacing-0.5 px-spacing-2 py-spacing-1 cursor-pointer ',
        isSelected ? 'bg-background-paper-elevation-1' : 'hover:bg-gray-100',
      )}
    >
      <Text>{option.label}</Text>
      {optionData?.parents && optionData.parents.length > 0 && (
        <div className="flex flex-row gap-spacing-1">
          {optionData.parents.length > 1 && (
            <Text variant={Text.variants.BODY2} color={Text.colors.TEXT_SECONDARY}>
              {optionData.parents[1].label}
            </Text>
          )}
          <Text
            variant={optionData.parents.length > 1 ? Text.variants.BODY2_BOLD : Text.variants.BODY2}
            color={Text.colors.TEXT_SECONDARY}
          >
            {optionData.parents[0].label}
          </Text>
        </div>
      )}
    </li>
  );
};
