import React, { forwardRef, useEffect, useMemo, useRef } from 'react';
import { CoreComponent, CoreComponentProps, useCountryList, useMergedRef } from '@react-fe/core';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { FormControl } from '@react-fe/common-ui';
import { AddressFormControlNames } from '../../address-form.constants';
import { Namespaces } from '@react-fe/expertunity-base/constants';
import { useFormikContext } from 'formik';
import { BaseStore, useTypedBaseStore } from '@react-fe/expertunity-base/stores';

export type CountryInputProps = CoreComponentProps & { required: boolean };

export const CountryInput: CoreComponent<CountryInputProps, HTMLInputElement> = forwardRef<
  HTMLInputElement,
  CountryInputProps
>(({ id, 'data-testid': dataTestId, className, required }, ref) => {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();
  const { currentCountry } = useTypedBaseStore<BaseStore>();
  const countryList = useCountryList();
  const internalRef = useRef<HTMLInputElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);
  const classNames = cx(className);

  const handleCountryChange = () => {
    setFieldValue(AddressFormControlNames.ZIP, '');
    setFieldValue(AddressFormControlNames.CITY, '');
  };

  useEffect(() => {
    if (currentCountry) {
      setFieldValue(AddressFormControlNames.COUNTRY, currentCountry);
    }
  }, [currentCountry, setFieldValue]);

  const countryInputProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId,
      className: classNames,
      ref: mergedRef,
    }),
    [id, dataTestId, classNames, mergedRef],
  );

  return (
    <FormControl.Root name={AddressFormControlNames.COUNTRY} required={required} {...countryInputProps}>
      <FormControl.Dropdown
        required={required}
        label={t('address_form_country_label', { ns: Namespaces.ProjectsPage })}
        options={countryList.map(country => ({ label: country.long, value: country.short }))}
        onChange={handleCountryChange}
        fullWidth={true}
      />
    </FormControl.Root>
  );
});

CountryInput.displayName = 'CountryInput';

export default CountryInput;
