import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { Button, Checkbox, Chip, Divider, Menu, MenuItem } from '@react-fe/common-ui';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import { Namespaces } from '@react-fe/expertunity-base/constants';
import cx from 'classnames';
import { ChangeEvent, forwardRef, useEffect, useId, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAttachmentsCategoryList } from '../../../../hooks/use-attachments-category-list';
import { AttachmentWithIndex } from '../../../attachments-list/attachments-list.constants';
import { CategorizedAttachmentFormControlNames } from '../../../../categorized-attachments-area.constants';
import { useFormikContext } from 'formik';

export type ToCategorizeHeaderProps = CoreComponentProps & {
  fieldName: string;
  attachments: AttachmentWithIndex[];
  selectedIndexes: number[];
  setSelectedIndexes: (indexes: number[] | ((prevIndexes: number[]) => number[])) => void;
};

export const ToCategorizeHeader: CoreComponent<ToCategorizeHeaderProps, HTMLDivElement> = forwardRef<
  HTMLDivElement,
  ToCategorizeHeaderProps
>(({ id, 'data-testid': dataTestId, className, fieldName, attachments, selectedIndexes, setSelectedIndexes }, ref) => {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();
  const [categorizeAllCheckbox, setCategorizeAllCheckbox] = useState<boolean>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const sortAllCheckboxId = useId();
  const categories = useAttachmentsCategoryList();
  const internalRef = useRef<HTMLDivElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);
  const classNames = cx(className, 'flex flex-col gap-spacing-1');

  const handleCategoryClick = (category: string) => {
    const selectedAttachments = attachments.filter(attachment => selectedIndexes.includes(attachment.index));
    selectedAttachments.forEach(attachment => {
      setFieldValue(
        `${fieldName}[${attachment.index}].${CategorizedAttachmentFormControlNames.CATEGORY}`,
        category,
        true,
      );
    });

    setSelectedIndexes(prevIndexes =>
      prevIndexes.filter(index => !selectedAttachments.some(attachment => attachment.index === index)),
    );

    closeMenu();
  };

  const handleCategorizeAll = (value: ChangeEvent<HTMLInputElement>) => {
    setCategorizeAllCheckbox(value.target.checked);
    setSelectedIndexes(value.target.checked ? attachments.map(attachment => attachment.index) : []);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);
    setAnchorEl(null);
  };

  useEffect(() => {
    const allAttachmentsSelected =
      attachments.length > 0 && attachments.every((_, index) => selectedIndexes.includes(index));

    if (allAttachmentsSelected) {
      setCategorizeAllCheckbox(true);
    } else if (selectedIndexes.length === 0) {
      setCategorizeAllCheckbox(false);
    }
  }, [selectedIndexes, attachments]);

  const toCategorizeHeaderProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId,
      className: classNames,
      ref: mergedRef,
    }),
    [id, dataTestId, classNames, mergedRef],
  );

  return (
    <div {...toCategorizeHeaderProps}>
      <div className="flex items-center grow">
        <div className="grow">
          <label
            htmlFor={sortAllCheckboxId}
            className={'flex flex-row gap-spacing-2 items-center leading-none cursor-pointer w-fit'}
          >
            <Checkbox
              id={sortAllCheckboxId}
              className="p-spacing-1"
              value={categorizeAllCheckbox}
              indeterminate={categorizeAllCheckbox}
              onChange={handleCategorizeAll}
            />
            <span>
              {t('to_categorize_list_categorize_all', {
                ns: Namespaces.CategorizedAttachment,
                defaultValue: 'To sort',
              })}
            </span>
          </label>
        </div>
        <div className="relative pr-spacing-1">
          <Button.Root
            disabled={selectedIndexes.length === 0}
            type={Button.Root.types.OUTLINED}
            onClick={handleMenuOpen}
          >
            {t('to_categorize_list_select_category', {
              ns: Namespaces.CategorizedAttachment,
              defaultValue: 'Select category',
            })}
            <Button.Icon icon={menuOpen ? faChevronUp : faChevronDown} />
          </Button.Root>
          {selectedIndexes.length > 0 ? (
            <span className="absolute top-[-14px] right-0">
              <Chip label={selectedIndexes.length} color="primary" size={'small'} />
            </span>
          ) : null}
        </div>
        <Menu className="mt-spacing-1" anchorEl={anchorEl} open={menuOpen} onClose={closeMenu}>
          {categories.map((option, index) => (
            <MenuItem
              key={`category-menu-${index}`}
              onClick={() => handleCategoryClick(option.value)}
              disabled={option.disabled}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
      <Divider />
    </div>
  );
});

ToCategorizeHeader.displayName = 'ToCategorizeHeader';

export default ToCategorizeHeader;
