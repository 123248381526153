import { faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { faQuestion, faUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { faPhone } from '@fortawesome/pro-regular-svg-icons';
import { Heading, HeadingVariant, Icon } from '@react-fe/common-ui';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

interface HelpBoxProps {
  isVisible?: boolean;
}

export function HelpBox({ isVisible = false }: HelpBoxProps) {
  const { t } = useTranslation();

  return (
    <div
      className={cn(
        'bg-background-paper-elevation-0 rounded-half p-spacing-3 w-[350px] rounded-half fixed lg:sticky top:2/4 lg:top-[120px] right-0 transition-transform duration-300 ease-in-out transform lg:transform-none shadow-md lg:shadow-none',
        {
          'translate-x-0 z-40': isVisible,
          'translate-x-full': !isVisible,
        },
        'lg:translate-x-0 lg:relative lg:bottom-auto lg:right-auto',
      )}
    >
      <Heading variant={HeadingVariant.H6} className="font-medium">
        {t('help_header')}
      </Heading>
      <div className="pt-spacing-2 space-y-spacing-2">
        <a
          href={`mailto:${t('help_email')}`}
          className="border border-secondary-state-outlined-border hover:bg-secondary-state-hover p-spacing-1 flex items-center justify-center gap-spacing-1 rounded-[100px] text-secondary font-medium leading-[26px]"
        >
          <Icon icon={faEnvelope} className="w-4 h-4" />
          {t('help_email')}
        </a>
        <a
          href={`tel:${t('help_phone')}`}
          className="border border-secondary-state-outlined-border hover:bg-secondary-state-hover p-spacing-1 flex items-center justify-center gap-spacing-1 rounded-[100px] text-secondary font-medium leading-[26px]"
        >
          <Icon icon={faPhone} className="w-4 h-4" />
          {t('help_phone')}
        </a>
        <a
          href={t('help_link')}
          target="_blank"
          rel="noreferrer"
          className="border border-secondary-state-outlined-border hover:bg-secondary-state-hover p-spacing-1 flex items-center justify-center gap-spacing-1 rounded-[100px] text-secondary font-medium leading-[26px]"
        >
          <Icon icon={faQuestion} className="w-4 h-4" />
          {t('help')}
          <Icon icon={faUpRightFromSquare} className="w-4 h-4" />
        </a>
      </div>
    </div>
  );
}

export default HelpBox;
