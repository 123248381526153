import debounce from 'lodash/debounce';
import { useEffect, useMemo, useRef } from 'react';

export const useDebounce = <T extends (...args: any[]) => void>(callback: T, delay = 1000) => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = (...args: Parameters<T>) => {
      ref.current?.(...args);
    };

    return debounce(func, delay);
  }, [delay]);

  return debouncedCallback;
};

export default useDebounce;
