import {
  InputAdornment,
  Autocomplete as MuiAutocomplete,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  OutlinedInput,
  SxProps,
} from '@mui/material';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { ComponentDefaultTestId, getTestId, HelperText } from '@react-fe/common-ui';
import { CoreComponent, CoreComponentProps, ElementContent, useMergedRef, withStaticProps } from '@react-fe/core';
import { ChangeEventHandler, forwardRef, useMemo, useRef } from 'react';
import { TextFieldVariant } from './text-field.constants';
import './text-field.scss';

export interface TextFieldProps extends CoreComponentProps {
  value?: string;
  label?: string;
  required?: boolean;
  name?: string;
  type?: 'text' | 'number' | 'email' | 'password';
  multiline?: boolean;
  maxRows?: number;
  rows?: number;
  placeholder?: string;
  select?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  error?: boolean;
  autoComplete?: boolean;
  autoCompleteOptions?: any[];
  autoCompleteOpen?: boolean;
  autoCompleteOnOpen?: () => void;
  autoCompleteOnClose?: () => void;
  autoCompleteRenderOption?: (props: React.HTMLAttributes<HTMLLIElement>, option: any) => ElementContent;
  autoCompleteGetOptionLabel?: (option: any) => string;
  autoCompleteNoOptionsText?: ElementContent | string;
  autoCompleteFreeSolo?: boolean;
  autoCompleteMultiple?: boolean;
  autoCompleteRenderTags?: (value: any, getTagProps: any) => ElementContent;
  autoCompleteLimitTags?: number;
  autoCompleteGroupBy?: (option: any) => string;
  autoCompleteFilterOptions?: (options: any[], state: any) => any[];
  autoCompleteDisablePortal?: boolean;
  autoCompleteSx?: SxProps;
  customSx?: SxProps;
  helperText?: string;
  helperLink?: {
    linkText: string;
    href: string;
  };
  variant?: TextFieldVariant;
  onClick?: (e?: React.MouseEvent<any>) => void;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
  trailingIcon?: ElementContent;
  InputProps?: Partial<OutlinedInputProps>;
  children?: ElementContent;
}

const TextFieldComponent: CoreComponent<TextFieldProps, HTMLInputElement> & { variants?: typeof TextFieldVariant } =
  forwardRef<HTMLInputElement, TextFieldProps>(
    (
      {
        id,
        'data-testid': dataTestId,
        className,
        value,
        label = '',
        required = false,
        name = '',
        type = 'text',
        multiline = false,
        rows,
        maxRows,
        placeholder,
        select = false,
        disabled,
        readOnly = false,
        error = false,
        autoComplete = false,
        autoCompleteOptions = [],
        autoCompleteOpen,
        autoCompleteOnOpen,
        autoCompleteOnClose,
        autoCompleteRenderOption,
        autoCompleteGetOptionLabel,
        autoCompleteNoOptionsText,
        autoCompleteFreeSolo = true,
        autoCompleteMultiple = false,
        autoCompleteRenderTags,
        autoCompleteLimitTags,
        autoCompleteGroupBy,
        autoCompleteFilterOptions,
        autoCompleteDisablePortal = true,
        autoCompleteSx,
        customSx,
        helperText = '',
        helperLink,
        variant = TextFieldVariant.OUTLINED,
        onChange,
        onBlur,
        onFocus,
        onClick,
        trailingIcon,
        InputProps,
        children,
        ...otherProps
      },
      ref,
    ) => {
      const internalRef = useRef<HTMLInputElement>(null);
      const mergedRef = useMergedRef(ref, internalRef);
      const componentSx = useMemo(() => {
        if (readOnly) {
          return {
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: '1px !important',
              borderColor: !error && 'rgba(0, 0, 0, 0.23) !important',
            },
            ...customSx,
          } as any;
        } else if (disabled) {
          return {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0, 0, 0, 0.23) !important',
              ...customSx,
            },
          } as any;
        }
        return customSx;
      }, [readOnly, disabled, customSx, error]);

      const textFieldProps: MuiTextFieldProps = useMemo(
        () => ({
          id,
          'data-testid': dataTestId || getTestId(ComponentDefaultTestId.TEXT_FIELD, id),
          ref: mergedRef,
          className: className,
          value,
          label,
          required,
          name,
          type,
          multiline,
          rows,
          maxRows,
          placeholder,
          select,
          disabled,
          sx: componentSx,
          InputProps: {
            ...InputProps,
            readOnly: readOnly,
          },
          error,
          helperText: !error ? <HelperText helperText={helperText} helperLink={helperLink} /> : '',
          variant: variant as any,
          onBlur,
          onFocus,
          ...otherProps,
        }),
        [
          id,
          dataTestId,
          mergedRef,
          className,
          value,
          label,
          required,
          name,
          type,
          multiline,
          rows,
          maxRows,
          placeholder,
          select,
          disabled,
          readOnly,
          error,
          helperText,
          helperLink,
          variant,
          onBlur,
          onFocus,
          InputProps,
          componentSx,
          otherProps,
        ],
      );

      if (autoComplete) {
        const { ref, name, InputProps, ...otherTextFieldProps } = textFieldProps;

        const autoCompletePopperVisibilityProps = {
          open: autoCompleteOpen,
          onOpen: autoCompleteOnOpen,
          onClose: autoCompleteOnClose,
        };

        return (
          <MuiAutocomplete
            {...(autoCompleteOpen !== undefined ? autoCompletePopperVisibilityProps : {})}
            disablePortal={autoCompleteDisablePortal}
            onChange={(changed, value = '') => {
              if (onChange) {
                onChange(value as any);
              }
            }}
            onBlur={(e: any) => {
              if (onBlur) {
                onBlur(e);
              }
            }}
            autoHighlight={true}
            options={autoCompleteOptions}
            renderOption={autoCompleteRenderOption}
            getOptionLabel={autoCompleteGetOptionLabel}
            noOptionsText={autoCompleteNoOptionsText}
            freeSolo={autoCompleteFreeSolo}
            multiple={autoCompleteMultiple}
            groupBy={autoCompleteGroupBy}
            limitTags={autoCompleteLimitTags}
            filterOptions={autoCompleteFilterOptions}
            value={value}
            renderTags={autoCompleteRenderTags}
            sx={autoCompleteSx}
            renderInput={params => (
              <MuiTextField
                {...params}
                {...otherTextFieldProps}
                onClick={onClick}
                onChange={onChange}
                onBlur={onBlur}
                InputProps={{
                  ...params.InputProps,
                  ...InputProps,
                  startAdornment: (
                    <>
                      {InputProps?.startAdornment}
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
              >
                {children}
              </MuiTextField>
            )}
          />
        );
      }

      if (trailingIcon) {
        const { type, margin, onInvalid, onKeyDown, children, onKeyUp, ...otherProps } = textFieldProps;
        return (
          <OutlinedInput
            {...otherProps}
            onChange={onChange}
            onClick={onClick}
            endAdornment={<InputAdornment position="end">{trailingIcon}</InputAdornment>}
          />
        );
      }

      return (
        <MuiTextField {...textFieldProps} onChange={onChange} onClick={onClick}>
          {children}
        </MuiTextField>
      );
    },
  );

export const TextField = withStaticProps(TextFieldComponent, {
  variants: TextFieldVariant,
});

TextField.displayName = 'FormInput';

export default TextField;
