export enum ComponentDefaultTestId {
  BUTTON = 'button',
  TEXT = 'text',
  TOOLTIP = 'tooltip',
  DIALOG = 'dialog',
  TABLE = 'table',
  POPOVER = 'popover',
  CHECKBOX = 'checkbox',
  SWITCH = 'switch',
  FORM_CONTROL = 'form_control',
  FORM_CONTROL_LABEL = 'form_control_label',
  FORM_CONTROL_TEXT_FIELD = 'form_control_text_field',
  FORM_CONTROL_TEXT_EDITOR = 'form_control_text_editor',
  FORM_CONTROL_CHECKBOX = 'form_control_checkbox',
  FORM_CONTROL_SWITCH = 'form_control_switch',
  FORM_CONTROL_RADIO_GROUP = 'form_control_radio_group',
  FORM_CONTROL_DATEPICKER = 'form_control_datepicker',
  FORM_CONTROL_DATE_CALENDAR = 'form_control_date_calendar',
  FORM_CONTROL_DIGITAL_CLOCK = 'form_control_digital_clock',
  FORM_CONTROL_FILE_UPLOAD = 'form_control_file_upload',
  FORM_CONTROL_AUTOCOMPLETE = 'form_control_autocomplete',
  TEXT_FIELD = 'text_field',
  TEXT_EDITOR = 'text_editor',
  TABS = 'tabs',
  SEARCH_FIELD = 'search_field',
  RICH_TEXT_BAR = 'rich_text_bar',
  FORM_ERROR = 'form_error',
  SPINNER = 'spinner',
  ALERT = 'alert',
  PROGRESS_BAR = 'progress_bar',
  RADIO_GROUP = 'radio_group',
  DATEPICKER = 'datepicker',
  INPUT_LABEL = 'input_label',
  FORM_HELPER_TEXT = 'form_helper_text',
  INPUT = 'input',
}
