import React, { forwardRef, ReactNode, useMemo, useRef } from 'react';
import { CoreComponent, CoreComponentProps, useMergedRef } from '@react-fe/core';
import { Box, Tab, Tabs as MUITabs } from '@mui/material';
import { ComponentDefaultTestId, getTestId } from '@react-fe/common-ui';
import cx from 'classnames';
import './tabs.scss';

export interface TabProps {
  label: ReactNode;
  value: string;
}

export interface TabsProps extends CoreComponentProps {
  tabs: TabProps[];
  value: string;
  onChange?: (value: any) => void;
}

export const Tabs: CoreComponent<TabsProps, HTMLDivElement> = forwardRef<HTMLDivElement, TabsProps>(
  ({ id, 'data-testid': dataTestId, className, tabs, value, onChange }, ref) => {
    const internalRef = useRef<HTMLDivElement>(null);
    const mergedRef = useMergedRef(ref, internalRef);

    const handleChange = (event: React.SyntheticEvent, value: any): void => {
      if (onChange) {
        onChange(value);
      }
    };

    const tabsProps = useMemo(
      () => ({
        id,
        'data-testid': dataTestId || getTestId(ComponentDefaultTestId.TABS, id),
        className: cx(className, 'BfgTabs'),
        ref: mergedRef,
        tabs,
        value,
        onChange,
      }),
      [id, dataTestId, className, mergedRef, tabs, value, onChange],
    );

    return (
      <Box {...tabsProps}>
        <MUITabs value={value} onChange={handleChange}>
          {tabs.map((tab: TabProps) => (
            <Tab key={tab.value} value={tab.value} label={tab.label} />
          ))}
        </MUITabs>
      </Box>
    );
  },
);

Tabs.displayName = 'Tabs';

export default Tabs;
