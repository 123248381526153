import React, { cloneElement, forwardRef, ReactElement, useId, useMemo, useRef } from 'react';
import { CoreComponent, CoreComponentProps, ElementContent, useMergedRef } from '@react-fe/core';
import { ComponentDefaultTestId, getTestId } from '@react-fe/common-ui';
import { FormControlTextField } from './form-control-text-field';
import { FormControlCheckbox } from './form-control-checkbox';
import { FormControlSwitch } from './form-control-switch';
import { FormControlDropdown } from './form-control-dropdown';
import { FormControlTextEditor } from './form-control-text-editor';
import { FormControlRadioGroup } from './form-control-radio-group';
import { FormControlDatepicker } from './form-control-datepicker';
import { FormControl as MUIFormControl } from '@mui/material';
import { FormControlDateCalendar } from './form-control-date-calendar';
import { FormControlDigitalClock } from './form-control-digital-clock';
import FormControlFileUpload from './form-control-file-upload';
import cx from 'classnames';
import FormControlMultiSelectDropdown from './form-control-multi-select-dropdown';
import FormControlAutocomplete from './form-control-autocomplete';

export interface FormControlRootProps extends CoreComponentProps {
  name?: string;
  required?: boolean;
  children?: ElementContent;
}

export const FormControlRoot: CoreComponent<FormControlRootProps, HTMLDivElement> = forwardRef<
  HTMLDivElement,
  FormControlRootProps
>(({ id, 'data-testid': dataTestId, className, children, name, required = false }, ref) => {
  const internalRef = useRef<HTMLDivElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);
  const classNames = cx(className, 'flex flex-col');
  const generatedId = useId();
  const formControlId = id ? id : generatedId;

  const formControlProps = {
    id,
    'data-testid': dataTestId || getTestId(ComponentDefaultTestId.FORM_CONTROL, id),
    className: classNames,
    ref: mergedRef,
    required,
  };

  const enhancedChildren = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      if (
        child.type === FormControlTextField ||
        child.type === FormControlCheckbox ||
        child.type === FormControlSwitch ||
        child.type === FormControlDropdown ||
        child.type === FormControlTextEditor ||
        child.type === FormControlFileUpload ||
        child.type === FormControlRadioGroup ||
        child.type === FormControlDatepicker ||
        child.type === FormControlDateCalendar ||
        child.type === FormControlDigitalClock ||
        child.type === FormControlMultiSelectDropdown ||
        child.type === FormControlAutocomplete
      ) {
        return cloneElement(child as ReactElement, {
          id: formControlId,
          name,
        });
      }
    }
    return child;
  });

  return <MUIFormControl {...formControlProps}>{enhancedChildren}</MUIFormControl>;
});

FormControlRoot.displayName = 'FormControl.Root';

export default FormControlRoot;
