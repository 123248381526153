import { faFlag, faHandsWash, faQuestion } from '@fortawesome/pro-solid-svg-icons';
import { Button, Icon, IconSize, Title } from '@react-fe/common-ui';
import { getHours } from 'date-fns';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DashboardContent from '../../components/dashboard-content/dashboard-content';
import HelpBox from '../../components/help-box/help-box';

export function Dashboard() {
  // Mobile only state
  const [isHelpBoxVisible, setIsHelpBoxVisible] = useState(false);

  const { t } = useTranslation();
  const hour = getHours(new Date());

  const title = useMemo(() => {
    if (hour >= 5 && hour < 12) {
      return t('good_morning');
    }
    if (hour >= 12 && hour < 18) {
      return t('good_afternoon');
    }
    return t('good_evening');
  }, [hour, t]);

  return (
    <div className="flex flex-col gap-4 grow relative">
      <Title title={title} icon={faHandsWash}>
        <Button.Root>
          <Button.Icon icon={faFlag}></Button.Icon>
          <p>{t('guided_tour')}</p>
        </Button.Root>
      </Title>
      <div className="flex lg:gap-spacing-3 items-start">
        <div className="bg-background-paper-elevation-0 rounded-lg flex-1 flex rounded-half px-spacing-3 py-[26px]">
          <DashboardContent />
        </div>

        <HelpBox isVisible={isHelpBoxVisible} />

        <div className="lg:hidden">
          <Button.Root
            onClick={() => setIsHelpBoxVisible(!isHelpBoxVisible)}
            type={Button.Root.types.OUTLINED}
            className="fixed top-2/4 right-0 z-50 !px-4 !py-6 text-white !rounded-none"
          >
            <Icon icon={faQuestion} size={IconSize.LARGE} />
          </Button.Root>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
