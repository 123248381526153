import { Autocomplete, FormControl, FormError, OptionItem, TextField } from '@react-fe/common-ui';
import { CoreComponent, CoreComponentProps, useDebounce, useMergedRef } from '@react-fe/core';
import { Namespaces } from '@react-fe/expertunity-base/constants';
import { useGeonames } from '@react-fe/expertunity-base/hooks';
import { Geoname } from '@react-fe/expertunity-base/models';
import cx from 'classnames';
import { useFormikContext } from 'formik';
import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddressFormControlNames } from '../../address-form.constants';

export type CityInputProps = CoreComponentProps & { required: boolean };

export const CityInput: CoreComponent<CityInputProps, HTMLInputElement> = forwardRef<HTMLInputElement, CityInputProps>(
  ({ id, 'data-testid': dataTestId, className, required }, ref) => {
    const internalRef = useRef<HTMLInputElement>(null);
    const mergedRef = useMergedRef(ref, internalRef);
    const classNames = cx(className, 'w-1/2');

    const { t } = useTranslation();
    const { setFieldValue, getFieldMeta, setFieldTouched, getFieldProps, validateForm, values } = useFormikContext();

    const [inputValue, setInputValue] = useState('');
    const [selectedZipCode, setSelectedZipCode] = useState<string>('');

    const { data } = useGeonames(
      getFieldMeta(AddressFormControlNames.CITY).value as string,
      getFieldMeta(AddressFormControlNames.COUNTRY).value as string,
      getFieldMeta(AddressFormControlNames.COUNTRY).value as string,
      'placename',
    );

    const zipCode = (values as any)[AddressFormControlNames.ZIP];
    const fieldValue = (values as any)[AddressFormControlNames.CITY];
    const debouncedValidateForm = useDebounce(validateForm, 500);
    const fieldErrors = getFieldMeta(AddressFormControlNames.CITY).error;

    const sortedOptions = useMemo(() => {
      return (data?.postalCodes || []).sort((a, b) => {
        const postalCodeA = a.postalCode || '';
        const postalCodeB = b.postalCode || '';
        return postalCodeA.localeCompare(postalCodeB);
      });
    }, [data?.postalCodes]);

    const getOptionLabel = useCallback((option: Geoname | string) => {
      if (typeof option === 'string') return option;
      return `${option.postalCode}, ${option.placeName}`;
    }, []);

    const isOptionEqualToValue = useCallback((option: string | Geoname, value: string | Geoname) => {
      if (typeof option === 'string' || typeof value === 'string') {
        return (
          (typeof option === 'string' ? option : option.placeName) ===
          (typeof value === 'string' ? value : value.placeName)
        );
      }
      return option.placeName === value.placeName && option.postalCode === value.postalCode;
    }, []);

    const extractCityName = useCallback((input: string): string => {
      if (!input) return '';

      const trimmedInput = input.trim();
      if (!trimmedInput.includes(',')) return trimmedInput;

      const parts = trimmedInput.split(',');
      if (parts.length < 2) return trimmedInput;

      const cityPart = parts.slice(1).join(',').trim();
      return cityPart || trimmedInput;
    }, []);

    const handleInputChange = useCallback(
      (newInputValue: string) => {
        const cityName = extractCityName(newInputValue);
        setInputValue(cityName);
        setFieldValue(AddressFormControlNames.CITY, cityName);

        if (!cityName) {
          setSelectedZipCode('');
          setFieldValue(AddressFormControlNames.ZIP, '');
        }

        debouncedValidateForm();
      },
      [setFieldValue, extractCityName, debouncedValidateForm],
    );

    const handleChange = useCallback(
      (newValue: string | Geoname | null) => {
        if (newValue && typeof newValue !== 'string') {
          setFieldValue(AddressFormControlNames.CITY, newValue.placeName);
          if (newValue.postalCode) {
            setSelectedZipCode(newValue.postalCode);
            setFieldValue(AddressFormControlNames.ZIP, newValue.postalCode);
          }
        } else {
          setFieldValue(AddressFormControlNames.CITY, newValue || '', true);
          setSelectedZipCode('');
          setFieldValue(AddressFormControlNames.ZIP, '');
        }

        debouncedValidateForm();
      },
      [setFieldValue, debouncedValidateForm],
    );

    const renderOption = useCallback(
      (props: any, option: Geoname) => {
        const { key: _, ...restProps } = props as any;
        const isSelected = isOptionEqualToValue(option, {
          postalCode: selectedZipCode,
          placeName: fieldValue,
          countryCode: getFieldMeta(AddressFormControlNames.COUNTRY).value as string,
        });

        return (
          <OptionItem
            key={`city-input-render-option-${option.postalCode.concat(option.placeName)}`}
            isSelected={isSelected}
            option={{ value: option.placeName, label: `${option.postalCode}, ${option.placeName}` }}
            {...restProps}
          />
        );
      },
      [isOptionEqualToValue, getFieldMeta, fieldValue, selectedZipCode],
    );

    const handleBlur = useCallback(() => {
      setFieldTouched(AddressFormControlNames.CITY, true, true).catch();
    }, [setFieldTouched]);

    const cityInputProps = useMemo(() => {
      return {
        id,
        'data-testid': dataTestId,
        className: classNames,
        ref: mergedRef,
      };
    }, [id, dataTestId, classNames, mergedRef]);

    useEffect(() => {
      setSelectedZipCode(zipCode || '');
    }, [zipCode]);

    return (
      <FormControl.Root name={AddressFormControlNames.CITY} {...cityInputProps}>
        <Autocomplete
          value={fieldValue}
          inputValue={inputValue}
          onInputChange={(_, newInputValue) => handleInputChange(newInputValue)}
          onChange={(_, newValue) => handleChange(newValue)}
          options={sortedOptions}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          isOptionEqualToValue={isOptionEqualToValue}
          renderInput={params => (
            <TextField
              {...params}
              required={required}
              error={!!fieldErrors}
              label={t('address_form_city_label', { ns: Namespaces.ProjectsPage })}
              placeholder={t('address_form_city_placeholder', { ns: Namespaces.ProjectsPage })}
              onBlur={handleBlur}
            />
          )}
        />
        <FormError field={getFieldProps(AddressFormControlNames.CITY)} />
      </FormControl.Root>
    );
  },
);

CityInput.displayName = 'CityInput';

export default CityInput;
