import { faArrowRightFromBracket, faBuilding, faUser } from '@fortawesome/pro-regular-svg-icons';
import { Avatar, Button, Icon, Menu, MenuItem, Text } from '@react-fe/common-ui';
import { Namespaces } from '@react-fe/expertunity-base';
import useBaseStore from '@react-fe/expertunity-base/stores';
import { LanguagesModal } from '@react-fe/i18n-utils';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { Link } from 'react-router-dom';
import { USER_MENU_CUSTOM_SX } from './header.constants';

export function Header() {
  const auth = useAuth();
  const { t } = useTranslation();
  const { currentUser } = useBaseStore();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const openMenu = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  }, []);
  const menuOpen = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <header className={'flex justify-between'}>
        <div className="h-[36px]" />
        <div className="flex flex-row items-center gap-spacing-2">
          <LanguagesModal />
          {currentUser?.firstname && (
            <Button.Root type={Button.Root.types.GHOST} size={Button.Root.sizes.SMALL} onClick={openMenu}>
              <Avatar name={`${currentUser?.firstname} ${currentUser?.lastname}`} />
            </Button.Root>
          )}
        </div>
      </header>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={closeMenu}
        className="mt-spacing-1"
        customSx={USER_MENU_CUSTOM_SX}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem>
          <Link
            onClick={() => closeMenu()}
            to="/settings/my-profile"
            className={'cursor-pointer flex items-center gap-spacing-1 p-spacing-1'}
          >
            <Icon icon={faUser} />
            <Text>{t('my_profile', { ns: Namespaces.Common, defaultValue: 'My profile' })}</Text>
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            onClick={() => closeMenu()}
            to="/settings/company-profile"
            className={'cursor-pointer flex items-center gap-spacing-1 py-spacing-1 px-1.5'}
          >
            <Icon icon={faBuilding} />
            <Text>{t('company_profile', { ns: Namespaces.Common, defaultValue: 'Company profile' })}</Text>
          </Link>
        </MenuItem>
        <MenuItem onClick={() => void auth.signoutRedirect()}>
          <div className={'cursor-pointer flex items-center gap-spacing-1 py-spacing-1 px-1.5'}>
            <Icon icon={faArrowRightFromBracket} />
            <Text>{t('sign_out', { ns: Namespaces.Common, defaultValue: 'Sign out' })}</Text>
          </div>
        </MenuItem>
      </Menu>
    </>
  );
}

export default Header;
