import { faFileInvoice, faLayerGroup } from '@fortawesome/pro-regular-svg-icons';
import { Namespaces } from '@react-fe/expertunity-base/constants';
import { Navigate, RouteObject } from 'react-router-dom';

export const router: RouteObject = {
  path: 'projects',
  handle: {
    crumb: () => ({ to: '/projects', name: 'projects', namespace: Namespaces.Common, icon: faLayerGroup }),
  },
  children: [
    {
      index: true,
      async lazy() {
        const { Projects } = await import('./projects');
        return { Component: Projects };
      },
    },
    {
      path: ':projectUid',
      async lazy() {
        const { ProjectRoot } = await import('./project');
        return { Component: ProjectRoot };
      },
      handle: {
        crumb: () => ({ to: '/project', name: 'project', namespace: Namespaces.Common, icon: faLayerGroup }),
      },
      children: [
        {
          path: '',
          async lazy() {
            const { Project } = await import('./project');
            return { Component: Project };
          },
          children: [
            {
              path: '',
              element: <Navigate to="tenders" replace={true} />,
            },
            {
              path: 'tenders',
              children: [
                {
                  path: '',
                  element: <Navigate to="all" replace={true} />,
                },
                {
                  path: 'all',
                  async lazy() {
                    const { TenderList: Tenders } = await import('./project/components/tender-list');
                    return {
                      Component: Tenders,
                    };
                  },
                },
                {
                  path: 'actionable',
                  async lazy() {
                    const { TenderList: Tenders } = await import('./project/components/tender-list');
                    return {
                      Component: Tenders,
                    };
                  },
                },
                {
                  path: 'draft',
                  async lazy() {
                    const { TenderList: Tenders } = await import('./project/components/tender-list');
                    return {
                      Component: Tenders,
                    };
                  },
                },
                {
                  path: 'open',
                  async lazy() {
                    const { TenderList: Tenders } = await import('./project/components/tender-list');
                    return {
                      Component: Tenders,
                    };
                  },
                },
                {
                  path: 'review',
                  async lazy() {
                    const { TenderList: Tenders } = await import('./project/components/tender-list');
                    return {
                      Component: Tenders,
                    };
                  },
                },
                {
                  path: 'awarded',
                  async lazy() {
                    const { TenderList: Tenders } = await import('./project/components/tender-list');
                    return {
                      Component: Tenders,
                    };
                  },
                },
                {
                  path: 'archived',
                  async lazy() {
                    const { TenderList: Tenders } = await import('./project/components/tender-list');
                    return {
                      Component: Tenders,
                    };
                  },
                },
              ],
            },
            {
              path: 'details',
              async lazy() {
                const { ProjectDetails } = await import('./project/tabs/project-details');
                return { Component: ProjectDetails };
              },
            },
          ],
        },
        {
          path: 'tenders/:tendersTab',
          async lazy() {
            const { TendersTabRoot } = await import('./project');
            return { Component: TendersTabRoot };
          },
          handle: {
            crumb: () => ({ to: '/tenders', name: 'tenders', namespace: Namespaces.Common, icon: faLayerGroup }),
          },
          children: [
            {
              path: ':tenderUid',
              async lazy() {
                const { TenderRoot } = await import('./tender');
                return { Component: TenderRoot };
              },
              handle: {
                crumb: () => ({
                  to: '/tender',
                  name: 'tender',
                  namespace: Namespaces.Common,
                  icon: faFileInvoice,
                }),
              },
              children: [
                {
                  path: '',
                  async lazy() {
                    const { Tender } = await import('./tender');
                    return { Component: Tender };
                  },
                  children: [
                    {
                      path: '',
                      element: <Navigate to="offers" replace={true} />,
                    },
                    {
                      path: 'contractors',
                      async lazy() {
                        const { Contractors } = await import('./tender/tabs/contractors');
                        return { Component: Contractors };
                      },
                    },
                    {
                      path: 'offers',
                      async lazy() {
                        const { Offers } = await import('./tender/tabs/offers');
                        return { Component: Offers };
                      },
                    },
                    {
                      path: 'details',
                      async lazy() {
                        const { TenderDetails } = await import('./tender/tabs/tender-details');
                        return { Component: TenderDetails };
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
