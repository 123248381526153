import { Avatar, Text } from '@react-fe/common-ui';
import { Namespaces } from '@react-fe/expertunity-base';
import { ClientUser } from '@react-fe/expertunity-base/models';
import { useTranslation } from 'react-i18next';

export const CompanyDirectoryDetailsContactCard = ({ user }: { user: ClientUser }) => {
  const { t } = useTranslation();

  return (
    <div
      className="flex flex-row gap-spacing-2 py-spacing-2 px-spacing-3 border border-grey-300 w-[450px] rounded-lg"
      key={user.uid}
    >
      <Avatar name={`${user.firstname} ${user.lastname}`} />
      <div className="flex flex-col gap-spacing-1 w-full overflow-auto">
        <div className="flex flex-col gap-spacing-0.5">
          <Text variant={Text.variants.BODY2} color={Text.colors.TEXT_SECONDARY}>
            {user.function
              ? user.function
              : t('no_function_defined', { ns: Namespaces.Settings, defaultValue: 'No function' })}
          </Text>
          <Text variant={Text.variants.BODY1_BOLD}>{`${user.firstname} ${user.lastname}`}</Text>
        </div>

        <div className="flex flex-col gap-spacing-1 w-fit max-w-full">
          {user.email && (
            <a href={`mailto:${user.email}`}>
              <Text className="underline" variant={Text.variants.BODY2} color={Text.colors.LINK} ellipsis>
                {user.email}
              </Text>
            </a>
          )}
          {user.phone && (
            <a href={`tel:${user.phone}`}>
              <Text className="underline" variant={Text.variants.BODY2} color={Text.colors.LINK} ellipsis>
                {user.phone}
              </Text>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
