import React, { useCallback, useEffect, useMemo } from 'react';
import { CoreComponentProps, ElementContent } from '@react-fe/core';
import { Button, Chip, Heading, SearchField, Skeleton, Text, TextFieldVariant } from '@react-fe/common-ui';
import { Namespaces } from '@react-fe/expertunity-base/constants';
import { useTranslation } from 'react-i18next';
import { RichTreeView, TreeItem, TreeViewBaseItem } from '@mui/x-tree-view';
import { HierarchicalItemMapper } from './utils/hierarchical-item.mapper';
import { filterTreeItems } from './utils/filter-tree-items';
import { HierarchicalItem } from '@react-fe/expertunity-base/models';
import { flattenTreeItems } from './utils/flatten-tree-items';
import { Close as CloseIcon } from '@mui/icons-material';

export interface TreeItemProps {
  itemId: string;
  children?: ElementContent;
}

const CustomTreeItem: React.FC<TreeItemProps> = ({ ...props }) => {
  const { children, ...otherProps } = props;

  return (
    <TreeItem {...otherProps} classes={{ checkbox: children ? '!hidden' : '!block' }}>
      {children}
    </TreeItem>
  );
};

export interface HierarchicalItemsModalProps extends CoreComponentProps {
  handleClose: () => void;
  handleConfirm: (results: HierarchicalItem[]) => void;
  currentSelectedItems: HierarchicalItem[];
  title: string;
  description: string;
  placeholder: string;
  isLoading: boolean;
  items?: HierarchicalItem[];
  translateLabel: (label: string) => string;
}

export const HierarchicalItemsModalContent: React.FC<HierarchicalItemsModalProps> = ({
  'data-testid': dataTestId,
  handleClose,
  handleConfirm,
  currentSelectedItems,
  title,
  description,
  placeholder,
  isLoading,
  items,
  translateLabel,
}: HierarchicalItemsModalProps) => {
  const { t } = useTranslation();
  const [selectedItems, setSelectedItems] = React.useState<string[]>(currentSelectedItems.map(item => item.uid));
  const [searchValue, setSearchValue] = React.useState('');
  const [expandedItems, setExpandedItems] = React.useState<string[]>([]);

  const getParentIds = useCallback((items: HierarchicalItem[], targetId: string): string[] => {
    const result: string[] = [];
    const findParent = (currentItems: HierarchicalItem[]) => {
      for (const item of currentItems) {
        if (item.uid === targetId) {
          return true;
        }
        if (item.children.length > 0) {
          if (findParent(item.children)) {
            result.push(item.uid);
            return true;
          }
        }
      }
      return false;
    };
    findParent(items);
    return result;
  }, []);

  useEffect(() => {
    if (items) {
      const allParentIds = new Set<string>();
      currentSelectedItems.forEach(item => {
        const parentIds = getParentIds(items, item.uid);
        parentIds.forEach(id => allParentIds.add(id));
      });
      setExpandedItems(Array.from(allParentIds));
    }
  }, [items, currentSelectedItems, getParentIds]);

  const translateItem = useCallback(
    (item: HierarchicalItem): HierarchicalItem => {
      return {
        ...item,
        label: translateLabel ? translateLabel(item.label) : item.label,
        children: item.children.map(translateItem),
      };
    },
    [translateLabel],
  );

  const treeItems: TreeViewBaseItem[] | undefined = useMemo(() => {
    const translatedItems = items?.map(translateItem);
    return translatedItems?.map(HierarchicalItemMapper.toTreeViewFormat);
  }, [items, translateItem]);

  const findItem = useCallback(
    (items: HierarchicalItem[], uid: string): HierarchicalItem | undefined => {
      const currentItem = currentSelectedItems.find(item => item.uid === uid);
      if (currentItem) return currentItem;

      for (const item of items) {
        if (item.uid === uid) return item;
        if (item.children.length > 0) {
          const found = findItem(item.children, uid);
          if (found) return found;
        }
      }
      return undefined;
    },
    [currentSelectedItems],
  );

  const handleCancelClick = () => {
    handleClose();
  };

  const handleAddClick = () => {
    handleConfirm(
      selectedItems
        .map(uid => findItem(items || [], uid))
        .filter((item): item is HierarchicalItem => item !== undefined),
    );
  };

  const handleSearchChange = (searchValue: string) => {
    setSearchValue(searchValue);
  };

  const handleSelectedItemsChange = (event: React.SyntheticEvent, newSelectedItems: string[]) => {
    setSelectedItems(newSelectedItems);
  };

  const handleExpandedItemsChange = (event: React.SyntheticEvent, itemIds: string[]) => {
    setExpandedItems(itemIds);
  };

  const handleChipDelete = (itemId: string) => {
    setSelectedItems(prevItems => prevItems.filter(id => id !== itemId));
  };

  const filteredTreeItems = useMemo(() => {
    if (!searchValue) {
      setExpandedItems([]);
      return treeItems;
    }
    const filtered = filterTreeItems(treeItems || [], searchValue, t);
    const allMatchingIds = flattenTreeItems(filtered).map(item => item.id);
    setExpandedItems(allMatchingIds);
    return filtered;
  }, [treeItems, searchValue, t]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      handleAddClick();
    }
  };

  return (
    <div onKeyDown={handleKeyDown} tabIndex={0} data-testid={dataTestId} className="flex flex-col gap-spacing-3 h-full">
      <div className="flex flex-col gap-spacing-0.5">
        <Heading variant={Heading.variants.H4}>{title}</Heading>
        <Text color={Text.colors.TEXT_SECONDARY}>{description}</Text>
      </div>

      <SearchField
        variant={TextFieldVariant.STANDARD}
        label={t('search', { ns: Namespaces.Common })}
        disabled={isLoading}
        onChange={handleSearchChange}
        placeholder={placeholder}
      />

      {selectedItems && selectedItems.length > 0 && (
        <div className="flex flex-wrap gap-spacing-1">
          {selectedItems.map(itemId => {
            const item = findItem(items || [], itemId);
            return (
              <Chip
                key={`selected-item-key-${itemId}`}
                label={item ? translateLabel(item.label) : itemId}
                onDelete={() => handleChipDelete(itemId)}
                deleteIcon={<CloseIcon />}
              />
            );
          })}
        </div>
      )}

      {isLoading ? (
        Array.from({ length: 8 }).map((value, index) => (
          <Skeleton key={`item-skeleton-${index}`} height={24} variant={'rectangular'} />
        ))
      ) : (
        <RichTreeView
          multiSelect
          checkboxSelection
          items={filteredTreeItems ?? []}
          selectedItems={selectedItems}
          expandedItems={expandedItems}
          onSelectedItemsChange={handleSelectedItemsChange}
          onExpandedItemsChange={handleExpandedItemsChange}
          className="overflow-y-auto"
          slots={{
            item: CustomTreeItem,
          }}
        />
      )}

      <div className="flex flex-row gap-spacing-1 justify-end mt-auto">
        <Button.Root type={Button.Root.types.OUTLINED} onClick={handleCancelClick}>
          {t('cancel', {
            ns: Namespaces.Common,
            defaultValue: 'Cancel',
          })}
        </Button.Root>

        <Button.Root data-testid={'test-button-add'} type={Button.Root.types.PRIMARY} onClick={handleAddClick}>
          {t('add', {
            ns: Namespaces.Common,
            defaultValue: 'Add',
          })}
        </Button.Root>
      </div>
    </div>
  );
};

export default HierarchicalItemsModalContent;
