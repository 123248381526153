import { formatDate } from 'date-fns';
import { useCallback } from 'react';
import { useLocale } from '../use-locale';

export const useDateFormatter = (): {
  dateFormatter: (date: string | number | Date, formatString?: string) => string;
} => {
  const { locale } = useLocale();
  const dateFormatter = useCallback(
    (date: string | number | Date, formatString = 'P') => {
      if (!date) {
        return '';
      }

      return formatDate(date, formatString, { locale });
    },
    [locale],
  );

  return { dateFormatter };
};
