import { Avatar as MUIAvatar } from '@mui/material';
import { CoreComponentProps } from '@react-fe/core';
import { useMemo } from 'react';
import { Text } from '../text';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { Icon } from '../icon';

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string, icon?: IconDefinition, width?: number, height?: number) {
  const nameSplit = name.match(/[A-Za-z]+/g) || [];
  let initials = '';

  if (nameSplit.length > 1) {
    initials = `${nameSplit[0]?.[0]}${nameSplit[1]?.[0]}`;
  } else if (nameSplit.length === 1) {
    initials = `${nameSplit[0]?.[0]}`;
  }

  return {
    sx: {
      width: width ?? 40,
      height: height ?? 40,
      bgcolor: stringToColor(name),
      fontSize: '0.75rem',
    },
    children: icon ? (
      <Icon icon={icon} className="text-xl" />
    ) : (
      <Text variant={Text.variants.INHERIT}>{initials.toUpperCase()}</Text>
    ),
  };
}

export interface AvatarProps extends CoreComponentProps {
  name: string;
  icon?: IconDefinition;
  width?: number;
  height?: number;
}

export function Avatar({ name, className, icon, width, height }: AvatarProps) {
  const avatarProps = useMemo(() => stringAvatar(name, icon, width, height), [name, icon, width, height]);
  return <MUIAvatar {...avatarProps} className={className} />;
}

export default Avatar;
