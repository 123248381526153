import { faEnvelope, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { Heading, Icon, Text } from '@react-fe/common-ui';
import { useCountryName } from '@react-fe/core';
import { ContactCardPerson } from '@react-fe/expertunity-base/models';

export const ContactCardContactDetails = ({ contact }: { contact: ContactCardPerson }) => {
  const { countryName } = useCountryName();

  return (
    <div className="flex flex-row gap-spacing-2">
      {contact?.logoUrl && <img src={contact?.logoUrl} alt="company logo" className="w-[50px] h-[50px]" />}
      <div className="flex flex-col gap-spacing-1 overflow-hidden">
        <Heading variant={Heading.variants.H6}>{`${contact?.firstName} ${contact?.lastName}`}</Heading>
        {contact?.function && <Text>{contact?.function}</Text>}
        {contact?.companyName && <Text>{contact?.companyName}</Text>}
        {contact?.department && <Text>{contact?.department}</Text>}
        {contact?.street && <Text>{`${contact?.street}, ${contact?.zip} ${contact?.city}`}</Text>}
        {contact?.country_code && <Text>{countryName(contact?.country_code)}</Text>}
        {contact?.email && (
          <div className="flex items-center gap-spacing-1">
            <Icon icon={faEnvelope} />
            <Text className="underline" color={Text.colors.LINK} ellipsis>
              {contact?.email}
            </Text>
          </div>
        )}
        {contact?.phone && (
          <div className="flex items-center gap-spacing-1">
            <Icon icon={faPhone} />
            <Text className="underline" color={Text.colors.LINK} ellipsis>
              {contact?.phone}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};
