import FormControlLabel from './form-control-label';
import FormControlRoot from './form-control-root';
import FormControlTextField from './form-control-text-field';
import FormControlCheckbox from './form-control-checkbox';
import FormControlSwitch from './form-control-switch';
import FormControlDropdown from './form-control-dropdown';
import FormControlTextEditor from './form-control-text-editor';
import FormControlFileUpload from './form-control-file-upload';
import FormControlRadioGroup from './form-control-radio-group';
import FormControlDatepicker from './form-control-datepicker';
import FormControlDateCalendar from './form-control-date-calendar';
import FormControlDigitalClock from './form-control-digital-clock';
import FormControlMultiSelectDropdown from './form-control-multi-select-dropdown';
import FormControlAutocomplete from './form-control-autocomplete';

export * from './form-control-root';
export * from './form-control-file-upload';

export const FormControl = {
  Root: FormControlRoot,
  Label: FormControlLabel,
  TextField: FormControlTextField,
  Checkbox: FormControlCheckbox,
  Switch: FormControlSwitch,
  Dropdown: FormControlDropdown,
  MultiSelectDropdown: FormControlMultiSelectDropdown,
  TextEditor: FormControlTextEditor,
  FileUpload: FormControlFileUpload,
  RadioGroup: FormControlRadioGroup,
  Datepicker: FormControlDatepicker,
  DateCalendar: FormControlDateCalendar,
  DigitalClock: FormControlDigitalClock,
  Autocomplete: FormControlAutocomplete,
};
