import { ComponentDefaultTestId, getTestId } from '@react-fe/common-ui';
import { CoreComponent } from '@react-fe/core';
import { useField } from 'formik';
import { forwardRef, useMemo } from 'react';
import { Autocomplete, AutocompleteProps } from '../autocomplete';
import { FormError } from '../form-error';
import { FormControlRootProps } from './form-control-root';

type FormControlMergedProps = FormControlRootProps & Omit<AutocompleteProps<any, any, any, any>, 'ref'>;

export const FormControlAutocomplete: CoreComponent<FormControlMergedProps, HTMLDivElement> = forwardRef<
  HTMLDivElement,
  FormControlMergedProps
>(({ id, 'data-testid': dataTestId, name = '', helperText, ...otherProps }, ref) => {
  const [field, meta] = useField(name);
  const hasErrors = meta.touched && !!meta.error;

  const formControlMergedProps = useMemo(
    () => ({
      'data-testid': dataTestId || getTestId(ComponentDefaultTestId.FORM_CONTROL_AUTOCOMPLETE, id),
      helperText: hasErrors ? '' : helperText,
      ...otherProps,
    }),
    [dataTestId, hasErrors, helperText, id, otherProps],
  );

  return (
    <div className="flex flex-col">
      <Autocomplete {...field} {...formControlMergedProps} error={hasErrors} />
      <FormError field={field} />
    </div>
  );
});

FormControlAutocomplete.displayName = 'FormControl.Autocomplete';

export default FormControlAutocomplete;
