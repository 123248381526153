export enum ModalKeys {
  ACCEPT_REJECT_CONTRACTORS_MODAL = 'accept-reject-contractors-modal',
  ACCEPT_REJECT_OFFER_MODAL = 'accept-reject-offer-modal',
  EXTEND_SUBMISSION_DEADLINE_MODAL = 'extend-submission-deadline-modal',
  DELETE_TENDER_MODAL = 'delete-tender-modal',
  CREATE_TENDER_MODAL = 'create-tender-modal',
  BUILDING_TYPES_MODAL = 'building-types-modal',
  CONTACT_PERSON_MODAL = 'contact-person-modal',
  INVITE_CONTRACTORS_MODAL = 'invite-contractors-modal',
  INVITE_CONTRACTORS_WITH_EXTENDED_SUBMISSION_DEADLINE_MODAL = 'invite-contractors-with-extended-submission-deadline-modal',
}
