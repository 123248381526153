import { Offer, ProjectDetails } from '@react-fe/expertunity-base/models';
import { StateCreator } from 'zustand';
import { Tender, TenderPreview, TenderTabName } from '../models';

export interface CommonBuyerState {
  tender: Tender | null;
  projectDetails: { data: ProjectDetails; isLoading: boolean } | null;
  offers: Offer[] | null;
  currentTendersTab: TenderTabName;
  allTenders: TenderPreview[];
  actionableTenders: TenderPreview[];
  draftTenders: TenderPreview[];
  openForOfferTenders: TenderPreview[];
  reviewTenders: TenderPreview[];
  awardedTenders: TenderPreview[];
  archivedTenders: TenderPreview[];
  isAllTendersLoading: boolean;
  isDeleteTenderModalOpen: boolean;
  isCreateProjectModalOpen: boolean;
  tendersSearchTerm: string[];
  setTender: (tender: Tender | null) => void;
  setProjectDetails: (details: { data: ProjectDetails; isLoading: boolean }) => void;
  setOffers: (offers: Offer[] | null) => void;
  setCurrentTendersTab: (tab: TenderTabName) => void;
  setAllTenders: (tenders: TenderPreview[]) => void;
  setActionableTenders: (tenders: TenderPreview[]) => void;
  setDraftTenders: (tenders: TenderPreview[]) => void;
  setOpenForOfferTenders: (tenders: TenderPreview[]) => void;
  setReviewTenders: (tenders: TenderPreview[]) => void;
  setAwardedTenders: (tenders: TenderPreview[]) => void;
  setArchivedTenders: (tenders: TenderPreview[]) => void;
  setIsAllTendersLoading: (isLoading: boolean) => void;
  setIsDeleteTenderModalOpen: (isOpen: boolean) => void;
  setIsCreateProjectModalOpen: (isOpen: boolean) => void;
  clearCommonBuyerState: () => void;
  setTendersSearchTerm: (term: string[]) => void;
}

export const createCommonBuyerSlice: StateCreator<CommonBuyerState> = set => ({
  tender: null,
  projectDetails: null,
  offers: null,
  currentTendersTab: TenderTabName.ALL,
  allTenders: [],
  actionableTenders: [],
  draftTenders: [],
  openForOfferTenders: [],
  reviewTenders: [],
  awardedTenders: [],
  archivedTenders: [],
  isAllTendersLoading: false,
  isDeleteTenderModalOpen: false,
  isCreateProjectModalOpen: false,
  tendersSearchTerm: [],
  setTender: (tender: Tender | null) => set({ tender }),
  setProjectDetails: projectDetails => set({ projectDetails }),
  setOffers: offers => set({ offers }),
  setCurrentTendersTab: tab => set({ currentTendersTab: tab }),
  setAllTenders: tenders => set({ allTenders: tenders }),
  setActionableTenders: tenders => set({ actionableTenders: tenders }),
  setDraftTenders: tenders => set({ draftTenders: tenders }),
  setOpenForOfferTenders: tenders => set({ openForOfferTenders: tenders }),
  setReviewTenders: tenders => set({ reviewTenders: tenders }),
  setAwardedTenders: tenders => set({ awardedTenders: tenders }),
  setArchivedTenders: tenders => set({ archivedTenders: tenders }),
  setIsAllTendersLoading: isLoading => set({ isAllTendersLoading: isLoading }),
  setIsDeleteTenderModalOpen: status => set({ isDeleteTenderModalOpen: status }),
  setIsCreateProjectModalOpen: status => set({ isCreateProjectModalOpen: status }),
  clearCommonBuyerState: () =>
    set({
      tender: null,
      projectDetails: null,
      offers: null,
      allTenders: [],
      actionableTenders: [],
      draftTenders: [],
      openForOfferTenders: [],
      reviewTenders: [],
      awardedTenders: [],
      archivedTenders: [],
      tendersSearchTerm: [],
    }),
  setTendersSearchTerm: term => set({ tendersSearchTerm: term }),
});
