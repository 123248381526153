import { CommonUIThemeProvider } from '@react-fe/common-ui';
import { BreadcrumbProvider } from '@react-fe/core';
import { YupLocaleProvider } from '@react-fe/expertunity-base';
import '@react-fe/expertunity-base/i18n-configuration';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { ToastProvider } from './components/toast-provider';
import ConfigProvider from './config-provider';
import './interceptors/response-interceptor';
import router from './routers/router';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const isDev = import.meta.env.MODE === 'development';

root.render(
  <StrictMode>
    <CommonUIThemeProvider>
      <BreadcrumbProvider>
        <QueryClientProvider client={queryClient}>
          <YupLocaleProvider>
            <ToastProvider>
              <ConfigProvider>
                <RouterProvider router={router} />
              </ConfigProvider>
            </ToastProvider>
            {isDev && <ReactQueryDevtools initialIsOpen={false} />}
          </YupLocaleProvider>
        </QueryClientProvider>
      </BreadcrumbProvider>
    </CommonUIThemeProvider>
  </StrictMode>,
);
