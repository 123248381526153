import { ModalKeys } from '@react-fe/buyer/models';
import { Button, Form, FormControl, Heading, Text, ToastType } from '@react-fe/common-ui';
import { CoreComponent, CoreComponentProps, useDateFormatter, useMergedRef, withStaticProps } from '@react-fe/core';
import { ContactPerson, useContactCardPopup } from '@react-fe/expertunity-base/components';
import { Namespaces } from '@react-fe/expertunity-base/constants';
import { useCurrencyFormatter, useModal, useToast } from '@react-fe/expertunity-base/hooks';
import useBaseStore from '@react-fe/expertunity-base/stores';
import cx from 'classnames';
import { Formik, FormikHelpers } from 'formik';
import { forwardRef, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useAcceptRejectOffer from '../../hooks/use-accept-reject-offer';
import { ActionMode } from './accept-reject-offer-modal.constants';

export interface AcceptRejectOfferModalProps extends CoreComponentProps {
  mode?: ActionMode;
}

const AcceptRejectOfferModalComponent: CoreComponent<AcceptRejectOfferModalProps, HTMLDivElement> & {
  mode?: typeof ActionMode;
} = forwardRef<HTMLDivElement, AcceptRejectOfferModalProps>(
  ({ id, 'data-testid': dataTestId, className, mode = AcceptRejectOfferModal.mode.ACCEPT }, ref) => {
    const { t } = useTranslation();
    const { closeModal } = useModal();
    const { dateFormatter } = useDateFormatter();
    const { currencyFormatter } = useCurrencyFormatter();
    const { offerToAcceptOrReject: offer } = useBaseStore();
    const { mutate } = useAcceptRejectOffer(mode, offer);
    const { displayToast } = useToast();
    const { openPopup, ContactCardPopupComponent } = useContactCardPopup();

    const internalRef = useRef<HTMLDivElement>(null);
    const mergedRef = useMergedRef(ref, internalRef);
    const classNames = cx(className);

    const acceptRejectOfferModalProps = useMemo(
      () => ({
        id,
        'data-testid': dataTestId,
        className: classNames,
        ref: mergedRef,
        mode,
      }),
      [id, dataTestId, classNames, mergedRef, mode],
    );

    if (!offer) {
      return null;
    }

    return (
      <div {...acceptRejectOfferModalProps}>
        <Heading className="!mb-spacing-1" variant={Heading.variants.H4}>
          {mode === ActionMode.ACCEPT
            ? t('accept_offer_title', { ns: Namespaces.Buyer })
            : t('reject_offer_title', { ns: Namespaces.Buyer })}
        </Heading>
        <Text className="!mb-spacing-3" color={Text.colors.TEXT_SECONDARY}>
          {mode === ActionMode.ACCEPT
            ? t('accept_offer_description', { ns: Namespaces.Buyer })
            : t('reject_offer_description', { ns: Namespaces.Buyer })}
        </Text>

        <div className="px-spacing-3 py-spacing-2 flex flex-col gap-spacing-2 bg-background-paper-elevation-0 mb-spacing-2">
          <Heading className="!mb-spacing-1" variant={Heading.variants.H5}>
            {offer.bidder_name}
          </Heading>

          <div className="flex justify-between border-b border-action-divider">
            <Text color={Text.colors.TEXT_SECONDARY}>{t('contact')}</Text>
            <div className="flex items-center gap-spacing-1">
              <ContactPerson
                onClick={openPopup}
                firstName={offer.contact.first_name}
                lastName={offer.contact.last_name}
              />
              <ContactCardPopupComponent contact={offer.contact} />
            </div>
          </div>

          <div className="flex flex-col gap-spacing-1">
            <div className="flex justify-between">
              <Text color={Text.colors.TEXT_SECONDARY}>{t('received')}</Text>
              <Text>{dateFormatter(offer.state_modified_at, 'Pp')}</Text>
            </div>
            <div className="flex justify-between">
              <Text color={Text.colors.TEXT_SECONDARY}>{t('status')}</Text>
              <Text>{t(`offer_state_${offer.state}`, { ns: Namespaces.TenderPage })}</Text>
            </div>
            <div className="flex justify-between">
              <Text color={Text.colors.TEXT_SECONDARY}>{t('trans_id')}</Text>
              <Text>{offer.name}</Text>
            </div>
            <div className="flex justify-between border-b border-action-divider">
              <Text color={Text.colors.TEXT_SECONDARY}>{t('source')}</Text>
              <Text>
                {offer.external
                  ? t('entry_location_outside', { ns: Namespaces.TenderPage })
                  : t('entry_location_platform', { ns: Namespaces.TenderPage })}
              </Text>
            </div>
          </div>

          <div className="flex justify-between border-b border-action-divider">
            <Text color={Text.colors.TEXT_SECONDARY}>{t('gross_excl_vat')}</Text>
            <Text>{currencyFormatter(offer.gross_excl_vat)}</Text>
          </div>
        </div>

        <Formik
          initialValues={{ message: '' }}
          onSubmit={(values, { setSubmitting }: FormikHelpers<any>) => {
            mutate(values.message, {
              onSuccess() {
                setSubmitting(false);
                displayToast({
                  type: ToastType.SUCCESS,
                  message: t(`${mode}_offer_success_message`, {
                    ns: Namespaces.TenderPage,
                  }),
                });
                setTimeout(() => {
                  closeModal(ModalKeys.ACCEPT_REJECT_OFFER_MODAL);
                }, 800);
              },
              onError() {
                setSubmitting(false);
                displayToast({
                  type: ToastType.ERROR,
                  message: t(`${mode}_offer_error_message`, {
                    ns: Namespaces.TenderPage,
                  }),
                });
              },
            });
          }}
        >
          <Form>
            <FormControl.Root name="message" className="mb-spacing-3">
              <FormControl.TextField multiline label={t('reason', { defaultValue: 'Reason' })} />
            </FormControl.Root>

            <div className="w-full flex gap-spacing-2 justify-end">
              <Button.Root
                type={Button.Root.types.OUTLINED}
                onClick={() => closeModal(ModalKeys.ACCEPT_REJECT_OFFER_MODAL)}
              >
                {t('cancel')}
              </Button.Root>
              <Button.Root inputType={Button.Root.inputType?.SUBMIT}>
                {t(`${mode}_offer`, { ns: Namespaces.Buyer })}
              </Button.Root>
            </div>
          </Form>
        </Formik>
      </div>
    );
  },
);

export const AcceptRejectOfferModal = withStaticProps(AcceptRejectOfferModalComponent, {
  mode: ActionMode,
});

AcceptRejectOfferModal.displayName = 'AcceptRejectOfferModal';

export default AcceptRejectOfferModal;
