import { Text } from '@react-fe/common-ui';
import { CoreComponent, CoreComponentProps, useDateFormatter, useMergedRef } from '@react-fe/core';
import { ContactPerson, Namespaces, useContactCardPopup } from '@react-fe/expertunity-base';
import { CreateOfferContactPerson } from '@react-fe/expertunity-base/models';
import useBaseStore from '@react-fe/expertunity-base/stores';
import cx from 'classnames';
import { forwardRef, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export type CreateOfferInfoProps = CoreComponentProps;

export const CreateOfferInfo: CoreComponent<CreateOfferInfoProps, HTMLDivElement> = forwardRef<
  HTMLDivElement,
  CreateOfferInfoProps
>(({ id, 'data-testid': dataTestId, className }, ref) => {
  const { t } = useTranslation();
  const { tenderDetails, project } = useBaseStore();
  const { dateFormatter } = useDateFormatter();
  const { openPopup, ContactCardPopupComponent } = useContactCardPopup();
  const internalRef = useRef<HTMLDivElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);
  const classNames = cx(
    className,
    'grid grid-flow-col auto-flow-max p-spacing-2 gap-spacing-1 bg-background-paper-elevation-1',
  );
  const submissionDate = tenderDetails?.submission_date
    ? dateFormatter(tenderDetails?.submission_date as string, 'Pp')
    : '';

  const createOfferInfoProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId,
      className: classNames,
      ref: mergedRef,
    }),
    [id, dataTestId, classNames, mergedRef],
  );

  const LabelValueDisplay = ({
    label = '',
    value = '',
    children,
    className,
  }: {
    className?: string;
    label?: string;
    value?: string;
    children?: React.ReactNode;
  }) => (
    <div className={cx('flex flex-col gap-spacing-0.5 overflow-x-hidden', className)}>
      <Text color={Text.colors.TEXT_SECONDARY} variant={Text.variants.CAPTION} ellipsis>
        {label}
      </Text>
      {children ? (
        children
      ) : (
        <Text variant={Text.variants.BODY1_BOLD} ellipsis>
          {value}
        </Text>
      )}
    </div>
  );

  return (
    <div {...createOfferInfoProps}>
      <LabelValueDisplay
        label={t('create_offer_info_tender', { ns: Namespaces.SellerCreateOfferPage, defaultValue: 'Tender:' })}
        value={`${tenderDetails?.name}`}
      />
      <LabelValueDisplay
        label={t('create_offer_info_project', { ns: Namespaces.SellerCreateOfferPage, defaultValue: 'Project:' })}
        value={`${project?.number}: ${project?.name}`}
      />
      <LabelValueDisplay
        label={t('create_offer_info_contact_person', {
          ns: Namespaces.SellerCreateOfferPage,
          defaultValue: 'Contact person:',
        })}
      >
        <ContactPerson
          onClick={openPopup}
          firstName={tenderDetails?.contact_person.firstName}
          lastName={tenderDetails?.contact_person.lastName}
        />
        <ContactCardPopupComponent contact={tenderDetails?.contact_person as CreateOfferContactPerson} />
      </LabelValueDisplay>
      <LabelValueDisplay
        className="place-self-end"
        label={t('create_offer_info_submit_offer_by', {
          ns: Namespaces.SellerCreateOfferPage,
          defaultValue: 'Submit offer by:',
        })}
        value={submissionDate}
      />
    </div>
  );
});

CreateOfferInfo.displayName = 'CreateOfferInfo';

export default CreateOfferInfo;
