import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { useCallback, useEffect, useState } from 'react';
import { Button } from '../components/button';
import { Icon } from '../components/icon';

interface UseHorizontalScrollingProps {
  scrollRef: React.RefObject<HTMLElement>;
  scrollAmount?: number;
  disabled?: boolean;
  buttonClassName?: string;
}

interface UseHorizontalScrollingReturn {
  canScrollLeft: boolean;
  canScrollRight: boolean;
  handleScroll: (direction: 'left' | 'right') => void;
  checkScroll: () => void;
  ScrollButtons: () => JSX.Element;
}

export const useHorizontalScrolling = ({
  scrollRef,
  scrollAmount = 350,
  disabled = false,
  buttonClassName = '!absolute top-1/2 -translate-y-1/2 z-10 !bg-primary !text-white w-[2.75rem] h-[1.75rem] z-index-1',
}: UseHorizontalScrollingProps): UseHorizontalScrollingReturn => {
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const checkScroll = useCallback(() => {
    const container = scrollRef.current;
    if (container) {
      setCanScrollLeft(container.scrollLeft > 0);
      setCanScrollRight(container.scrollLeft < container.scrollWidth - container.clientWidth);
    }
  }, [scrollRef]);

  useEffect(() => {
    if (disabled) return;

    checkScroll();
    window.addEventListener('resize', checkScroll);
    return () => window.removeEventListener('resize', checkScroll);
  }, [checkScroll, disabled]);

  const handleScroll = (direction: 'left' | 'right') => {
    const container = scrollRef.current;
    if (container) {
      const targetScroll =
        direction === 'left' ? container.scrollLeft - scrollAmount : container.scrollLeft + scrollAmount;

      container.scrollTo({
        left: targetScroll,
        behavior: 'smooth',
      });
    }
  };

  const ScrollButtons = () => (
    <>
      {canScrollLeft && (
        <Button.Root
          type={Button.Root.types.GHOST}
          className={`${buttonClassName} left-0`}
          onClick={() => handleScroll('left')}
        >
          <Icon icon={faChevronLeft} />
        </Button.Root>
      )}
      {canScrollRight && (
        <Button.Root
          type={Button.Root.types.GHOST}
          className={`${buttonClassName} right-0`}
          onClick={() => handleScroll('right')}
        >
          <Icon icon={faChevronRight} className="w-[0.75rem] h-[0.75rem]" />
        </Button.Root>
      )}
    </>
  );

  return {
    canScrollLeft,
    canScrollRight,
    handleScroll,
    checkScroll,
    ScrollButtons,
  };
};
