export enum QueryKeys {
  MY_OFFERS = 'my-offers',
  TENDER_OFFERS = 'tender-offers',
  TENDER_ATTACHMENTS = 'tender-attachments',
  OFFER_COMMENTS = 'offer-comments',
  CLIENT_DETAILS = 'client-details',
  CLIENT_USERS = 'client-users',
  POSTAL_CODES = 'postal-codes',
  ADMINISTRATIVE_DIVISIONS = 'administrative-divisions',
  CURRENT_USER = 'current-user',
}
