import { ComponentDefaultTestId, getTestId } from '@react-fe/common-ui';
import { CoreComponent } from '@react-fe/core';
import { useField } from 'formik';
import React, { forwardRef, useCallback, useMemo, useState, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { FormError } from '../form-error';
import { TextField, TextFieldProps } from '../text-field';
import { FormControlRootProps } from './form-control-root';

const INPUT_DELAY = 150;

type FormControlMergedProps = FormControlRootProps & TextFieldProps;

export const FormControlTextField: CoreComponent<FormControlMergedProps, HTMLInputElement> = forwardRef<
  HTMLInputElement,
  FormControlMergedProps
>(({ id, 'data-testid': dataTestId, name = '', helperText, onChange, ...otherProps }, ref) => {
  const [field, meta] = useField(name);
  const [innerValue, setInnerValue] = useState(field.value || '');
  const hasErrors = meta.touched && !!meta.error;

  useEffect(() => {
    if (field.value !== innerValue) {
      setInnerValue(field.value || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  const debouncedHandleOnChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(event);
  }, INPUT_DELAY);

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setInnerValue(newValue);
      debouncedHandleOnChange(event);
    },
    [debouncedHandleOnChange],
  );

  const formControlMergedProps = useMemo(
    () => ({
      'data-testid': dataTestId || getTestId(ComponentDefaultTestId.FORM_CONTROL_TEXT_FIELD, id),
      helperText: hasErrors ? '' : helperText,
      ...otherProps,
    }),
    [dataTestId, hasErrors, helperText, id, otherProps],
  );

  return (
    <div className="flex flex-col">
      <TextField
        {...field}
        {...formControlMergedProps}
        onChange={onChange || handleOnChange}
        value={innerValue}
        error={hasErrors}
        ref={ref}
      />
      <FormError field={field} />
    </div>
  );
});

FormControlTextField.displayName = 'FormControl.TextField';

export default FormControlTextField;
