import { faFileInvoice } from '@fortawesome/pro-regular-svg-icons';
import { Namespaces } from '@react-fe/expertunity-base/constants';
import { Navigate, RouteObject } from 'react-router-dom';

export const router: RouteObject = {
  path: 'tenders',
  handle: {
    crumb: () => ({ to: '/tenders', name: 'tenders', namespace: Namespaces.Common, icon: faFileInvoice }),
  },
  children: [
    {
      index: true,
      async lazy() {
        const { Tenders } = await import('./tenders');
        return { Component: Tenders };
      },
    },
    {
      path: ':tenderUid/projects/:projectUid',
      async lazy() {
        const { TenderRoot } = await import('./tender');
        return { Component: TenderRoot };
      },
      handle: {
        crumb: () => ({
          to: '/tender',
          name: 'tender',
          namespace: Namespaces.Common,
          icon: faFileInvoice,
        }),
      },
      children: [
        {
          path: '',
          async lazy() {
            const { Tender } = await import('./tender');
            return { Component: Tender };
          },
          children: [
            {
              path: '',
              element: <Navigate to="details" replace={true} />,
            },
            {
              path: 'details',
              async lazy() {
                const { Details } = await import('./tender/tabs/details');
                return { Component: Details };
              },
            },
            {
              path: 'my-offers',
              async lazy() {
                const { MyOffers } = await import('./tender/tabs/my-offers');
                return { Component: MyOffers };
              },
            },
            {
              path: 'summary',
              async lazy() {
                const { Summary } = await import('./tender/tabs/summary');
                return { Component: Summary };
              },
            },
          ],
        },
      ],
    },
  ],
};
