import { API_URL } from '@react-fe/expertunity-base/constants';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import { CurrentUser, QueryKeys } from '../models';

export function useUser(): UseQueryResult<CurrentUser> {
  return useQuery<CurrentUser>({
    queryKey: [QueryKeys.CURRENT_USER],
    queryFn: async (): Promise<CurrentUser> => {
      const response = await axios.get<CurrentUser>(`${API_URL}/matching/user`);
      return response.data;
    },
  });
}

export default useUser;
