import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../../components/header/header';
import Nav from '../../components/nav/nav';
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs';
import useBaseStore from '@react-fe/expertunity-base/stores';
import { ModalProvider } from '../../components/modal-provider';
import { Icon } from '@react-fe/common-ui';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { useUser } from '@react-fe/expertunity-base/hooks';

export function Root() {
  const { setCurrentUser } = useBaseStore();
  const { data, isLoading } = useUser();

  useEffect(() => {
    if (data) {
      setCurrentUser(data);
    }
  }, [data, setCurrentUser]);

  if (isLoading) {
    return (
      <div className={'flex items-center justify-center min-h-screen'}>
        <Icon icon={faSpinnerThird} className={'animate-spin size-40'} />
      </div>
    );
  }

  return (
    <div className="min-h-screen flex bg-background-default">
      <Nav />
      <main className={'grow flex flex-col pr-spacing-4 pb-spacing-4 pl-spacing-3 overflow-x-hidden'}>
        <div className="sticky top-0 z-10 pt-spacing-4 pb-spacing-3 bg-background-default">
          <Header />
          <Breadcrumbs />
        </div>
        <ModalProvider>
          <Outlet />
        </ModalProvider>
      </main>
    </div>
  );
}

export default Root;
