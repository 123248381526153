import { faFileArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { Portal } from '@mui/material';
import { CoreComponentProps, useMergedRef } from '@react-fe/core';
import cx from 'classnames';
import React, { DragEvent, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonType } from '../button';
import { FileInfoDisplay, FileInfoDisplayProps } from '../file-info-display';
import { Icon } from '../icon';
import { Text } from '../text';
import { Toast } from '../toast';
import { uploadMultipleFiles } from './hooks/use-upload-file';

export interface FileUploadInputProps<T> extends CoreComponentProps {
  value?: T | T[];
  multiple?: boolean;
  allowedFileExtensions?: string[];
  uploadEndpoint: string;
  onUploadSuccess?: (response: T | T[]) => void;
  onDeleteFile?: (deletedFile: T) => void;
  onUploadError?: (error: any) => void;
  maxFileSizeMb?: number;
  hideFileDisplay?: boolean;
  returnFileInfo?: boolean;
  fileNameExtractor?: (file: T) => string;
  fileSizeExtractor?: (file: T) => number;
}

interface FileItem<T> extends FileInfoDisplayProps {
  uploadedFile?: T;
}

function FileUploadInputInner<T>(
  {
    id,
    'data-testid': dataTestId,
    className,
    multiple = true,
    allowedFileExtensions = ['*/*'],
    uploadEndpoint,
    onUploadSuccess,
    onUploadError,
    maxFileSizeMb = 10,
    hideFileDisplay = false,
    returnFileInfo = false,
    value,
    fileNameExtractor = (file: any) => file.name || '',
    fileSizeExtractor = (file: any) => file.size || 0,
    onDeleteFile,
  }: FileUploadInputProps<T>,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const { t } = useTranslation();
  const internalRef = useRef<HTMLDivElement>(null);
  const mergedRef = useMergedRef(ref, internalRef);
  const inputRef = useRef<HTMLInputElement>(null);
  const [dragActive, setDragActive] = useState(false);
  const [errorToastOpen, setIsErrorToastOpen] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(false);

  const mapUploadedFileToInfoDisplay = useCallback(
    (value: T) => {
      const infoDisplay: FileItem<T> = {
        file: {
          name: fileNameExtractor(value),
          size: fileSizeExtractor(value),
        },
        progress: undefined,
        uploadedFile: value,
      };

      return infoDisplay;
    },
    [fileNameExtractor, fileSizeExtractor],
  );

  const setInitialFiles = useCallback(
    (value?: T | T[]) => {
      if (value) {
        return Array.isArray(value)
          ? value.map(file => mapUploadedFileToInfoDisplay(file))
          : [mapUploadedFileToInfoDisplay(value)];
      }
      return [];
    },
    [mapUploadedFileToInfoDisplay],
  );
  const [files, setFiles] = useState<FileItem<T>[]>(setInitialFiles(value));

  useEffect(() => {
    if (!multiple) {
      setInputDisabled(files.length > 0);
    } else {
      setInputDisabled(false);
    }
  }, [multiple, files]);

  const classNames = useMemo(
    () =>
      cx(
        className,
        'border-2 border-dashed border-bfg-brand-primary rounded p-4 text-center relative transition duration-300',
        {
          'bg-red-100': dragActive,
        },
      ),
    [className, dragActive],
  );

  const fileUploadInputProps = useMemo(
    () => ({
      id,
      'data-testid': dataTestId,
      className: classNames,
      ref: mergedRef,
      allowedFileExtensions,
      multiple,
      uploadEndpoint,
      returnFileInfo,
    }),
    [id, dataTestId, classNames, mergedRef, allowedFileExtensions, multiple, uploadEndpoint, returnFileInfo],
  );

  const handleDrag = useCallback((e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  }, []);

  const validateFileSize = useCallback(
    (file: File): boolean => {
      if (file.size > maxFileSizeMb * 1024 * 1024) {
        setIsErrorToastOpen(true);
        return false;
      }
      return true;
    },
    [maxFileSizeMb],
  );

  const handleUploadSuccess = useCallback(
    async (uploadedFiles: T[], file?: File) => {
      if (onUploadSuccess) {
        onUploadSuccess(uploadedFiles);
      }

      if (multiple) {
        setFiles(prevFiles =>
          prevFiles.map(f => {
            const uploadedFile = uploadedFiles.find(uploaded => uploaded === f.file);
            return uploadedFile ? { ...f, uploadedFile, progress: 100 } : f;
          }),
        );
      } else {
        setFiles(prevFiles =>
          prevFiles.map(f => (f.file === file ? { ...f, uploadedFile: uploadedFiles[0], progress: 100 } : f)),
        );
      }
    },
    [onUploadSuccess, multiple],
  );

  const handleUploadError = useCallback(
    (file: File, error: any) => {
      setFiles(prevFiles => prevFiles.map(f => (f.file === file ? { ...f, error } : f)));
      if (onUploadError) onUploadError(error);
    },
    [onUploadError],
  );

  const uploadFiles = useCallback(
    async (newFiles: File[]) => {
      if (!multiple) {
        setInputDisabled(true);
      }
      const validFiles = newFiles.filter(validateFileSize);
      const fileWithProgress = validFiles.map(file => ({ file, progress: 0 }));

      setFiles(prevFiles => [...prevFiles, ...fileWithProgress]);

      const uploadPayload = validFiles.map(file => {
        return {
          file,
          onProgress: (event: any) => {
            const progress = Math.round((event.loaded * 100) / event.total);
            setFiles(prevFiles => prevFiles.map(f => (f.file === file ? { ...f, progress } : f)));
          },
          onSuccess: (response: any) => {
            if (!multiple) {
              const uploaded = returnFileInfo ? ({ file, response } as unknown as T) : (response as T);
              handleUploadSuccess([uploaded], file);
            }
          },
          onError: (error: any) => {
            handleUploadError(file, error);
          },
        };
      });

      const results = await uploadMultipleFiles(uploadPayload, uploadEndpoint);

      if (multiple) {
        handleUploadSuccess(results);
      }
    },
    [multiple, validateFileSize, returnFileInfo, uploadEndpoint, handleUploadSuccess, handleUploadError],
  );

  const handleDrop = useCallback(
    (e: DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
      if (e.dataTransfer.files && !inputDisabled) {
        const newFiles = Array.from(e.dataTransfer.files);
        uploadFiles(newFiles);
      }
    },
    [inputDisabled, uploadFiles],
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const newFiles = Array.from(e.target.files);
        uploadFiles(newFiles);
      }
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    },
    [uploadFiles],
  );

  const openFileDialog = useCallback(() => {
    if (!inputDisabled && inputRef.current) {
      inputRef.current.click();
    }
  }, [inputDisabled]);

  const handleDelete = useCallback(
    (fileToDelete: File | { name: string; size: number }) => {
      const deletedFileItem = files.find(f => f.file === fileToDelete || f.file?.name === fileToDelete.name);
      setFiles(prevFiles => prevFiles.filter(f => f.file !== fileToDelete));
      if (!multiple) {
        setInputDisabled(false);
      }

      if (onDeleteFile && deletedFileItem?.uploadedFile) {
        onDeleteFile(deletedFileItem.uploadedFile);
      }
    },
    [files, multiple, onDeleteFile],
  );

  return (
    <div
      className={fileUploadInputProps.className}
      data-testid={fileUploadInputProps['data-testid']}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
    >
      <input
        type="file"
        id={`${fileUploadInputProps.id}-input`}
        className="hidden"
        onChange={handleChange}
        multiple={multiple}
        accept={fileUploadInputProps.allowedFileExtensions.join(',')}
        ref={inputRef}
        disabled={inputDisabled}
      />
      {files.length > 0 && !hideFileDisplay && (
        <div className="mt-2">
          {files.map(({ file, progress, error }, index) => (
            <FileInfoDisplay
              key={index}
              file={file}
              progress={progress}
              error={error}
              onDelete={() => handleDelete(file)}
            />
          ))}
        </div>
      )}
      {!inputDisabled && (
        <label
          htmlFor={`${id}-input`}
          className="flex flex-col items-center justify-center h-full cursor-pointer gap-2"
        >
          <Icon icon={faFileArrowUp} className="!text-info-state-outlined-contrast"></Icon>
          <div className="flex items-start gap-spacing-0.5">
            <Button.Root className="!p-0 !items-start !h-auto" type={ButtonType.GHOST} onClick={openFileDialog}>
              <Text
                className="!text-info-state-outlined-contrast underline whitespace-nowrap"
                variant={Text.variants.BODY2}
              >
                {t('upload_file_component_click_link', { defaultValue: 'Click to upload' })}
              </Text>
            </Button.Root>
            <Text variant={Text.variants.BODY2} className="whitespace-nowrap">
              {t('upload_file_component_drag_description', { defaultValue: 'or drag and drop' })}
            </Text>
          </div>
          <Text variant={Text.variants.BODY2} color={Text.colors.TEXT_SECONDARY} className="whitespace-nowrap">
            {`${allowedFileExtensions.join(', ')} (max. ${maxFileSizeMb}MB)`}
          </Text>
        </label>
      )}

      <Portal>
        <Toast
          className=""
          closable={true}
          handleClose={() => setIsErrorToastOpen(false)}
          open={errorToastOpen}
          type={Toast.type.ERROR}
          message={t('max_file_upload_error')}
        ></Toast>
      </Portal>
    </div>
  );
}

export const FileUploadInput = React.memo(forwardRef(FileUploadInputInner)) as <T>(
  props: FileUploadInputProps<T> & { ref?: React.ForwardedRef<HTMLDivElement> },
) => ReturnType<typeof FileUploadInputInner>;

export default FileUploadInput;
