import axios from 'axios';

import { User } from 'oidc-client-ts';
import { Config } from '@react-fe/expertunity-base/models';

export default (config: Config) => {
  function getUser() {
    const oidcStorage = sessionStorage.getItem(`oidc.user:${config.oidc_authority}:${config.oidc_client_id}`);
    if (!oidcStorage) {
      return null;
    }

    return User.fromStorageString(oidcStorage);
  }

  axios.interceptors.request.use(function (config) {
    const token = getUser()?.access_token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });
};
